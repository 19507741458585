<template>
  <div class="body">
    <!-- Similar Car List -->
    <h3>{{ $t("similarCar") }}</h3>
    <div v-if="state.loading" class="loading-screen">
      <i class="fa-solid fa-spinner"></i>
    </div>
    <div v-else class="car-list">
      <div
        class="item"
        v-for="(carItem, index) in state.similarCarArray"
        :key="index"
      >
        <CarItem
          :code="carItem.code"
          :id="carItem.id"
          :brandLogo="carItem.brand_image"
          :title="carItem.title"
          :location="carItem.location"
          :image="carItem.image"
          :price="carItem.price"
          :currency="carItem.currency"
        ></CarItem>
      </div>
    </div>
  </div>
</template>
<script setup>
import CarItem from "../item/carItem.vue";
import axios from "axios";
import config from "../../api/config.json";

import { onMounted, defineProps, reactive } from "vue";

const props = defineProps({
  id: String, // 租车ID
  category: Number, // 旅游方案 分类/国家
});

const state = reactive({
  similarCarArray: [],
  loading: true,
});

onMounted(() => {
  fetchCarData();
});

/**
 * 获取 租车列表 数据
 */
const fetchCarData = async () => {
  // 显示加载中
  state.loading = true;

  // 获取租车数据
  await axios
    .get(`${config.apiBaseUrl}/${config.version}/car/rental`, {
      params: { category: props.category },
    })
    .then((response) => {
      getCarData(response.data.data);
    })
    .catch((error) => {
      // 发生错误
      console.error("Error fetching similar cars:", error);
    })
    .finally(() => {
      // 执行就结束，结束加载中
      state.loading = false;
    });
};

/**
 * 将API获取到的资料加入similarCarArray
 * @param carResponse
 */
const getCarData = (carResponse) => {
  state.similarCarArray = [];
  carResponse.forEach((item) => {
    // 只取4个数据
    if (state.similarCarArray.length < 4) {
      let processedObject = {};

      // 排除当前显示着的旅游方案
      if (item.id != props.id) {
        for (let key in item) {
          if (Object.prototype.hasOwnProperty.call(item, key)) {
            let value = item[key];

            // 添加data
            processedObject[key] = value;
          }
        }
        // 将 已处理的 旅游方案 资料加入tourArray
        state.similarCarArray.push(processedObject);
      }
    }
  });
};
</script>
<style scoped>
/* Car List */
.body h3 {
  width: 96%;
  margin-top: 10px;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 2px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.15);

  @media (max-width: 991px) {
    width: 85%;
  }
}

.car-list {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: stretch;
  flex-wrap: wrap;
  flex-direction: row;

  @media (max-width: 991px) {
    flex-direction: column;
  }
}

.car-list .item {
  width: calc((100% / 4));

  @media (max-width: 991px) {
    width: 100%;
  }
}

/* Loading */
.loading-screen {
  width: 100%;
  height: 300px;
  text-align: center;
  padding-top: 50px;
}

.loading-screen i {
  color: #828282;
  font-size: 3em;

  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

<template>
  <div class="cruise">
    <div class="container">
      <h3><i class="fa-solid fa-ship"></i>{{ $t("cruise") }}</h3>
      <div class="spliter">
        <div class="lines-column"></div>
        <div class="lines-circle"></div>
        <div class="lines-column"></div>
      </div>
      <h5>{{ $t("excitingCruise") }}</h5>
      <div v-if="loading" class="loading-screen">
        <i class="fa-solid fa-spinner"></i>
      </div>
      <div v-else class="cruise-list">
        <div
          class="item"
          v-for="(cruiseItem, index) in cruiseArray"
          :key="index"
        >
          <CruiseItem
            :code="cruiseItem.code"
            :id="cruiseItem.id"
            :travelBy="cruiseItem.travel_by"
            :company="cruiseItem.company"
            :title="cruiseItem.title"
            :images="cruiseItem.images"
            :duration="cruiseItem.duration"
            :startDate="cruiseItem.start_date"
            :endDate="cruiseItem.end_date"
            :date="cruiseItem.date"
            :airfare="cruiseItem.airfare"
            :price="cruiseItem.price_per_person"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CruiseItem from "../../components/item/cruiseItem.vue";
import axios from "axios";
import config from "../../api/config.json";

export default {
  name: "App",
  components: {
    CruiseItem,
  },
  data() {
    return {
      // 游轮配套 样本数据
      cruiseArray: [],

      loading: true,
    };
  },
  mounted() {
    this.fetchCruiseData();
  },
  methods: {
    /**
     * 获取 游轮方案 数据
     */
    async fetchCruiseData() {
      let queryParams = {};
      // company: this.bramdValue || "",
      // title: this.nameValue || "",
      // destination: this.destinationValue || "",

      if (this.keyword && this.keyword != "")
        queryParams.keyword = this.keyword;

      queryParams.page = 1;
      queryParams.limit = 3;

      // 显示加载中
      this.loading = true;

      // 获取游轮方案数据
      await axios
        .get(`${config.apiBaseUrl}/${config.version}/cruise`, {
          params: queryParams,
        })
        .then((response) => {
          this.cruiseArray = response.data.data;
        })
        .catch((error) => {
          // 发生错误
          console.error("Error fetching cruises:", error);
        })
        .finally(() => {
          // 执行就结束，结束加载中
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
/* Cruise List */
.cruise {
  position: relative;
  width: 100%;
  height: auto;
  padding-top: 20px;
  padding-bottom: 30px;
}

.cruise .container {
  width: 1170px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 991px) and (max-width: 1260px) {
    width: 97%;
    padding-left: 15px;
  }

  @media (max-width: 991px) {
    width: 90%;
  }
}

.cruise .container h3 {
  width: 100%;
  text-align: center;
  color: #4d4c4c;
}

.cruise .container i {
  margin-right: 10px;
}

.cruise .container h5 {
  width: 100%;
  text-align: center;
  color: #838383;
}

.spliter {
  position: relative;
  width: 100%;
  height: auto;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spliter .lines-circle {
  position: relative;
  height: 12px;
  width: 12px;
  border: 1px solid #ebc131;
  border-radius: 100%;
  margin-left: 8px;
  margin-right: 8px;
}

.spliter .lines-column {
  position: relative;
  width: 100px;
  border-top: 1px solid #d9d9d9;
}

/* Cruise List */
.cruise-list {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: stretch;
  flex-wrap: wrap;
  flex-direction: row;

  @media (max-width: 991px) {
    flex-direction: column;
  }
}

.cruise-list .item {
  width: calc((100% / 3));

  @media (max-width: 991px) {
    width: 100%;
  }
}

/* Loading */
.loading-screen {
  width: 100%;
  height: 200px;
  margin-bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
}

.loading-screen i {
  color: #828282;
  font-size: 3em;

  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

<template>
  <div class="container">
    <h1>Forget Password</h1>
    <el-form>
      <el-form-item class="input-box" label="Old Password">
        <el-input v-model="form.oldPassword" />
      </el-form-item>
      <el-form-item class="input-box" label="New Password">
        <el-input v-model="form.newPassword" />
      </el-form-item>
      <el-form-item class="input-box" label="Re-enter New Password">
        <el-input v-model="form.secondNewPassword" />
      </el-form-item>
      <el-form-item class="button-list">
        <el-button class="button" type="primary" @click="onSubmit"
          >Confirm</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script setup>
import { reactive } from "vue";

const form = reactive({
  oldPassword: "",
  newPassword: "",
  secondNewPassword: "",
});

const onSubmit = () => {
  console.log("submit!");
};
</script>
<style scoped>
.container {
  height: 100%;
  padding: 50px;
  background: white;
}

h1 {
  margin: 0 0 30px 0;
}

/* Input Box */
.input-box {
  flex-direction: column !important;
}

.input-box::v-deep .el-form-item__label {
  justify-content: flex-start;
}

.input-box::v-deep .el-form-item__content {
  width: 500px;
}
</style>

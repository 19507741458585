<template>
  <div>
    <!-- Modal content -->
    <div class="modal-content">
      <!-- Modal header -->
      <div class="modal-header">
        <h2>{{ $t("contactUs") }}</h2>
      </div>
      <!-- Modal body -->
      <div class="modal-body">
        <iframe
          :src="props.reserveLink"
          frameborder="0"
          style="border: none; width: 100%; height: 400px"
        ></iframe>
      </div>
      <!-- Close button -->
      <div class="modal-footer">
        <button @click="closeModal">{{ $t("close") }}</button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, getCurrentInstance } from "vue";
// import { useStore } from 'vuex'

// const store = useStore();

const props = defineProps({
  reserveLink: String, // 官方Line
});

// const lineLink = computed(() => store.getters.getLine);

// 获取当前
const instance = getCurrentInstance();

/**
 * 关闭窗口
 */
const closeModal = () => {
  instance.emit("closeModal");
};
</script>
<style scoped>
.modal-content {
  background-color: #fefefe;
  margin: 3% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 45%;
  text-align: center;
  border-radius: 10px;
}

.modal-header {
  padding-bottom: 10px;
  text-align: left;
}

.modal-header h2 {
  margin: 0;
}

.modal-body {
  height: auto;
  margin: 10px 0 20px 0;
  padding: 20px 0;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.modal-body iframe {
  height: 550px !important;
}

.modal-footer {
  text-align: right;
}

.modal-footer button {
  margin-right: 10px;
  padding: 10px 20px;
  background: rgb(228, 228, 228);
  color: rgb(103, 103, 103);
  font-size: 1.2em;
  border: none;
  border-radius: 10px;
}

.modal-footer button:hover {
  background: rgb(199, 199, 199);
}
</style>

<template>
  <div class="container">
    <div class="header">
      <h2>
        <a @click="router.back()">&lt; {{ $t("orderDetail") }}</a>
      </h2>
      <div class="timeline">
        <div
          class="timeline-item"
          :class="orderObject.order_status >= 1 ? 'completed' : ''"
        >
          {{ $t("paymentPending") }}
        </div>
        <div
          class="timeline-item"
          :class="orderObject.order_status >= 2 ? 'completed' : ''"
        >
          {{ $t("paymentSettled") }}
        </div>
        <div
          class="timeline-item"
          :class="orderObject.order_status >= 3 ? 'completed' : ''"
        >
          {{ $t("ticketIssued") }}
        </div>
      </div>
    </div>
    <div class="order-info">
      <a
        ><i class="fa-solid fa-clipboard-list"></i> {{ $t("orderId") }}:
        {{ orderObject.order_id }}</a
      >
      <a
        ><i class="fa-regular fa-clock"></i> {{ $t("bookingDate") }}:
        {{ formatDate(orderObject.order_date) }}</a
      >
    </div>
    <h2 class="plan-title">{{ orderObject.travel_plan }}</h2>
    <div class="travel-detail">
      <div class="date-info">
        <div class="start-date">
          <strong>{{ $t("startDate") }}</strong>
          <a> {{ formatDateText(orderObject.start_date) }}</a>
        </div>
        <div class="end-date">
          <strong>{{ $t("endDate") }}</strong>
          <a> {{ formatDateText(orderObject.end_date) }}</a>
        </div>
      </div>
      <div class="travel-price">
        <a>
          <strong>{{ $t("numOfAdult") }}:</strong>
          {{ orderObject.number_of_adults }}
        </a>
        <a>
          <strong>{{ $t("numOfChild") }}:</strong>
          {{ orderObject.number_of_children }}
        </a>
        <a class="price"
          ><strong>{{ $t("totalPrice") }}:</strong>
          {{ orderObject.total_price }} {{ $t("baht") }}</a
        >
      </div>
    </div>
    <button class="line-button web-line" @click="showReserveModal = true">
      <i class="fa-brands fa-line"></i>{{ $t("contactForPayment") }}
    </button>
    <button class="line-button mobile-line" @click="moveToReserveLink()">
      <i class="fa-brands fa-line"></i>{{ $t("contactForPayment") }}
    </button>
  </div>
  <!-- Reserve Modal -->
  <Transition name="modal">
    <ReserveModal
      class="modal"
      v-if="showReserveModal"
      :reserveLink="`https://line.me/R/ti/p/%40${line}`"
      @closeModal="closeReserveModal"
    ></ReserveModal>
  </Transition>
</template>

<script setup>
import orderData from "../../json/order.json";
import ReserveModal from "../../components/reserveModal.vue";
import { computed, ref, getCurrentInstance } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

const moment = getCurrentInstance().appContext.config.globalProperties.$moment;

const route = useRoute();
const router = useRouter();
const store = useStore();

const showReserveModal = ref(false);

const orderArray = ref(orderData);

const orderObject = computed(() => {
  return orderArray.value.find(
    (order) => order.order_id === Number(route.query.id)
  );
});

// 从store获取 Line id
const line = computed(() => {
  return store.getters.getCompanyLineId;
  // return "gtq9153t";
});

/**
 * 关闭预约弹窗
 */
const closeReserveModal = () => {
  showReserveModal.value = false;
};

/**
 * 手机界面,
 * 点击后会跳至 Line页面，联系店家
 */
const moveToReserveLink = () => {
  window.open(`https://line.me/R/ti/p/%40${line.value}`);
};

/**
 * 显示YYYY-MM-DD版的日期
 */
const formatDate = (date) => {
  return moment(date * 1000).format("YYYY-MM-DD");
};

/**
 * 显示文字版的日期
 */
const formatDateText = (date) => {
  return moment(date * 1000).format("MMMM Do YYYY, dddd");
};
</script>

<style scoped>
/* Container */
.container {
  height: 100%;
}

/* Header */
.header {
  width: 100%;
  height: 22%;
  padding: 30px;
  color: white;
  background-color: rgb(122, 164, 226);

  @media (max-width: 650px) {
    height: 300px;
  }
}

.header h2 {
  font-size: 1.1em;

  a {
    margin-right: 15px;
    cursor: pointer;

    &:hover {
      color: rgb(89, 105, 159);
    }
  }

  @media (max-width: 991px) {
    a {
      margin-right: 5px;
    }
  }
}

/* Timeline */
.timeline {
  width: 600px;
  height: 60px;
  margin: 30px auto 0 auto;
  display: flex;
  justify-content: space-between;
  font-size: 1.3em;

  @media (max-width: 650px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
}

.timeline-item {
  position: relative;
  width: 220px;
  font-weight: bold;
  text-align: center;

  @media (max-width: 650px) {
    text-align: start;
    margin-bottom: 40px;
  }
}

.timeline-item::after {
  content: " ";
  position: absolute;
  top: 80%;
  left: 50%;
  border-radius: 50px;
  width: 20px;
  height: 20px;
  background-color: rgb(255, 255, 255);
  border: 6px solid rgb(186, 186, 186);
  z-index: 1;
}

.timeline-item:nth-child(2)::before,
.timeline-item:nth-child(3)::before {
  content: " ";
  position: absolute;
  top: 55px;
  right: 85px;
  width: 200px;
  height: 5px;
  background-color: rgb(186, 186, 186);
}

@media (max-width: 650px) {
  .timeline-item::after {
    top: 5px;
    left: -45px;
  }

  .timeline-item:nth-child(2)::before,
  .timeline-item:nth-child(3)::before {
    top: -45px;
    left: -37px;
    width: 5px;
    height: 60px;
  }
}
.completed {
  color: rgb(170, 240, 151) !important;
}

.completed::before {
  background-color: rgb(139, 216, 117) !important;
}

.completed::after {
  border-color: rgb(139, 216, 117) !important;
}

/* Order Info */
.order-info {
  display: flex;
  justify-content: space-between;
  padding: 10px 40px 10px 40px;
  background-color: rgba(77, 77, 77, 0.036);

  a {
    color: rgb(134, 164, 186);
    font-size: 1em;
    font-weight: bold;
  }

  @media (max-width: 400px) {
    flex-direction: column;
    text-align: center;

    a {
      padding: 2px 0;
    }
  }
}

/* Plan Title */
.plan-title {
  margin: 0 auto;
  padding: 20px 20px;
  color: rgb(60, 177, 255);
  text-align: center;
  /* cursor: pointer; */
  background-color: rgba(77, 77, 77, 0.036);
}
/*
.plan-title:hover {
  color: rgb(44, 154, 227);
  text-decoration: underline 2px;
} */

/* Travel Detail */
.travel-detail {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  border-bottom: 1px solid rgba(128, 128, 128, 0.14);

  @media (max-width: 750px) {
    flex-direction: column;
  }
}

/* Travel Date */
.date-info {
  width: 50%;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 750px) {
    width: 100%;
    padding: 20px 20px;
  }
}

.start-date {
  margin-bottom: 20px;
}

.end-date,
.start-date {
  display: flex;
  flex-direction: column;
  text-align: center;

  strong {
    color: rgb(136, 136, 136);
    font-size: 1.1em;
  }
  a {
    margin: 10px 0;
    color: rgb(83, 142, 181);
    font-size: 1.3em;
    font-weight: bold;
  }

  @media (max-width: 750px) {
    width: 100%;
    flex-direction: row;
    align-items: center;

    strong {
      text-align: start;
      width: 100px;
      font-size: 1em;
    }

    a {
      margin: 0;
      font-size: 1.1em;
    }
  }
}

/* Order Price */
.travel-price {
  width: 50%;
  padding: 10px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: start;
  border-left: 2px dashed rgba(128, 128, 128, 0.318);

  a {
    margin: 10px 0;
    color: rgb(44, 154, 227);
    font-size: 1em;
    font-weight: bold;
  }

  strong,
  .price strong {
    color: rgb(136, 136, 136);
    font-size: 1em;
  }

  .price {
    color: rgb(220, 178, 9);
    font-size: 1.4em;
  }

  @media (max-width: 750px) {
    width: 100%;
    border-left: none;
    border-top: 2px dashed rgba(128, 128, 128, 0.318);
  }
}

/* Payment */
.line-button {
  width: 70%;
  padding: 10px;
  margin: 25px auto 0 auto;
  background: #fff;
  font-size: 17px;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  color: #00b900;
  border: 2px #00b900 solid;
  @media (max-width: 991px) {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

.call-button i,
.line-button i {
  padding-right: 20px;
}

.line-button:hover {
  background: #00b900;
  color: #fff;
}

.web-line {
  display: block;

  @media (max-width: 991px) {
    display: none;
  }
}

.mobile-line {
  display: none;

  @media (max-width: 991px) {
    display: block;
  }
}

/* Reserve Modal */
.modal {
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  left: 0;
  top: 0;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);

  @media (max-width: 991px) {
    display: none;
  }
}

.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.2s;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}
</style>

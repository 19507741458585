import { createI18n } from "vue-i18n";
// import en from "./en.json";

// Function to load all JSON files dynamically using require.context
function loadLocaleMessages() {
  const locales = {};
  const files = require.context("./", true, /[A-Za-z0-9-_,\s]+\.json$/i);

  files.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      locales[locale] = files(key);
    }
  });

  return locales;
}

// Load all JSON messages
const localization = loadLocaleMessages();

// const localization = {
//   en: en,
// };

const i18n = new createI18n({
  legacy: false,
  locale: localStorage.getItem("locale"), // default locale
  fallbackLocale: "en",
  messages: localization,
});

export default i18n;

<template>
  <h3 style="margin-top: 25px">{{ $t("recommendedPrograms") }}</h3>
  <div v-if="loading" class="loading-screen">
    <i class="fa-solid fa-spinner"></i>
  </div>
  <div v-else class="package-display">
    <div class="item" v-for="(tourItem, index) in recommendArray" :key="index">
      <TourItem
        :id="tourItem.id"
        :code="tourItem.code"
        :travelBy="tourItem.travel_by"
        :title="tourItem.title"
        :images="tourItem.images"
        :numberOfDays="tourItem.number_of_days"
        :startDate="tourItem.start_date"
        :endDate="tourItem.end_date"
        :price="tourItem.price_per_person"
      />
    </div>
  </div>
</template>
<script>
import TourItem from "../../components/item/tourItem.vue";
import axios from "axios";
import config from "../../api/config.json";

export default {
  name: "App",
  components: {
    TourItem,
  },
  data() {
    return {
      recommendArray: [],

      loading: true,
    };
  },
  mounted() {
    this.fetchTourData();
  },
  methods: {
    /**
     * 获取 推荐旅游方案 数据
     */
    async fetchTourData() {
      let queryParams = {
        tag: "Recommended",
      };

      // 显示加载中
      this.loading = true;

      // 获取推荐旅游方案数据
      await axios
        .get(`${config.apiBaseUrl}/${config.version}/plan`, {
          params: queryParams,
        })
        .then((response) => {
          this.recommendArray = response.data.data.slice(0, 12);
        })
        .catch((error) => {
          // 发生错误
          console.error("Error fetching recommended tours:", error);
        })
        .finally(() => {
          // 执行就结束，结束加载中
          this.loading = false;
        });
    },

    /**
     * 将API获取到的资料加入 recommendArray
     * @param response
     */
    getData(response) {
      response.slice(0, 12).forEach((item) => {
        let processedObject = {};

        for (let key in item) {
          if (Object.prototype.hasOwnProperty.call(item, key)) {
            let value = item[key];

            // 如果该数据为 图片 及 文件网址，根据‘|’符号分裂string
            if (key === "images" || key === "document") {
              if (typeof value === "string" && value.includes("|")) {
                value = value.split("|");

                // 若无法分裂(即只有一个网址)，则而外将其加入一个array里面
              } else if (typeof value === "string" && !value.includes("|")) {
                value = [value];
              }
            }
            // 添加data
            processedObject[key] = value;
          }
        }
        // 将 已处理的 旅游方案 资料加入 recommendArray
        this.recommendArray.push(processedObject);
      });

      // console.log("recommend tourArray", this.recommendArray);
    },
  },
};
</script>
<style scoped>
/* Popular Tour Program List */
h3 {
  width: 98%;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 0;
  margin-bottom: 30px;
  padding: 15px;
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.15);

  @media (max-width: 991px) {
    width: 100%;
  }
}

/* Popular Package List */
.package-display {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: stretch;
  flex-wrap: wrap;
  flex-direction: row;

  @media (max-width: 991px) {
    flex-direction: column;
  }
}

.package-display .item {
  width: calc((100% / 3));

  @media (max-width: 991px) {
    width: 100%;
  }
}

/* Loading */
.loading-screen {
  width: 100%;
  height: 150px;
  text-align: center;
  padding-top: 50px;
}

.loading-screen i {
  color: #828282;
  font-size: 3em;

  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

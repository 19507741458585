<template>
  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css"
  />
  <div class="header">
    <el-container class="web-view">
      <el-header class="top-nav">
        <div class="container">
          <a @click="navigateToList('Promotional', '')">{{
            $t("promotionalTour")
          }}</a>
          |
          <!-- | <router-link to="/article">{{ $t("travelArticle") }}</router-link> | -->
          <router-link to="/about_us">{{ $t("aboutUs") }}</router-link> |
          <router-link to="/past_work">{{ $t("pastWork") }}</router-link> |
          <router-link to="/contact_us">{{ $t("contactUs") }}</router-link>
          <!-- <router-link v-if="!user || user == ''" to="/login">{{
            $t("loginOrRegister")
          }}</router-link>
          <router-link v-if="user || user != ''" to="/account/profile"
            >{{ $t("myAccount") }} |
          </router-link>
          <router-link v-if="user || user != ''" to="/" @click="logout()">{{
            $t("logout")
          }}</router-link> -->
        </div>
      </el-header>
      <el-main class="main-nav">
        <div class="container">
          <div class="contact">
            <!-- logo -->
            <div class="box--head-img-txt">
              <div class="box--hlogo">
                <a title="Sun Moon Tour and Travel">
                  <router-link to="/">
                    <figure>
                      <img
                        :src="companyProfileImage"
                        class="size--img-lg"
                        @error="
                          $event.target.src = require('../assets/no-image.svg')
                        "
                      />
                    </figure>
                  </router-link>
                </a>
              </div>
            </div>
            <!-- details -->
            <div class="detail-panel" style="display: flex">
              <!-- business hour -->
              <div style="display: flex; padding-right: 10px">
                <div class="detail-logo">
                  <i class="fa-regular fa-clock"></i>
                </div>
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    width: auto;
                    height: auto;
                    padding-left: 10px;
                  "
                >
                  <a style="font-weight: bold">{{ $t("businessHours") }}</a>
                  <a>{{ $t("mondayToFriday") }}</a>
                  <!-- <a>{{ businessHour }}</a> -->
                </div>
              </div>
              <!-- hotline -->
              <div class="hotline">
                <div>
                  <div class="detail-logo">
                    <i class="fa-solid fa-phone"></i>
                  </div>
                </div>
                <div class="hotline-info">
                  <a style="font-weight: bold">{{ $t("hotline") }}</a>
                  <a href="tel:06-2158-2884">06-2158-2884</a>
                  <a href="tel:06-2541-9051">06-2541-9051</a>
                </div>
              </div>
            </div>

            <!-- travel license -->
            <div>
              <a>Travel License : 11/03994</a>
              <!-- <a>Travel License : {{ travelLicense }}</a> -->
            </div>
          </div>
        </div>
        <!-- menu -->
        <div class="menu-container">
          <div class="container">
            <ul class="menu">
              <li class="tab">
                <router-link class="nav-route" to="/">{{
                  $t("homePage")
                }}</router-link>
              </li>
              <li class="foreign-tab tab">
                <a class="nav-route">{{ $t("internationalTours") }}</a>
                <div class="hover-panel foreign">
                  <ul class="hover-menu">
                    <li
                      class="hover-list"
                      v-for="(countryArray, index) in chunkedCountryArray"
                      :key="index"
                    >
                      <ul class="hover-sub-list">
                        <li
                          class="hover-card"
                          v-for="(country, index) in countryArray"
                          :key="index"
                          @click="navigateToList('', country.id)"
                        >
                          <img
                            :src="
                              country.image || require('../assets/no-image.svg')
                            "
                            @error="
                              $event.target.src = require('../assets/no-image.svg')
                            "
                          />
                          <a>{{ country.title }} {{ $t("tour") }}</a>
                          <div class="quantity">
                            <a> {{ country.plan_total }}</a>
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="tab">
                <router-link class="nav-route" to="/domestic_tour">{{
                  $t("domesticTours")
                }}</router-link>
              </li>
              <li class="tab">
                <router-link class="nav-route" to="/package_tour">{{
                  $t("tourPackage")
                }}</router-link>
              </li>
              <li class="tab" style="border-right: solid 1px #ddd">
                <router-link class="nav-route" to="/airline_ticket">{{
                  $t("bookFlightTicket")
                }}</router-link>
              </li>
              <!-- <li class="tab">
                <router-link class="nav-route" to="/admission_ticket"
                  >{{ $t("admissionTicket") }}s</router-link
                >
              </li>-->
              <li class="tab">
                <router-link class="nav-route" to="/cruise_list">{{
                  $t("cruiseShip")
                }}</router-link>
              </li>
              <li class="tab" style="border-right: solid 1px #ddd">
                <router-link class="nav-route" to="/car_rental">{{
                  $t("carRental")
                }}</router-link>
              </li>
            </ul>
          </div>
        </div>
      </el-main>
    </el-container>
    <el-container class="mobile-view">
      <el-header>
        <div class="left-nav">
          <!-- src="https://www.sunmoon.co.th/images/logo/mylogo.png" -->
          <router-link to="/">
            <img
              :src="companyProfileImage"
              @error="$event.target.src = require('../assets/no-image.svg')"
            />
          </router-link>
          <!-- hotline -->
          <div class="hotline">
            <div>
              <div class="detail-logo">
                <i class="fa-solid fa-phone"></i>
              </div>
            </div>
            <div class="hotline-info">
              <a style="font-weight: bold">{{ $t("hotline") }}</a>
              <a href="tel:06-2158-2884">06-2158-2884</a>
              <a href="tel:06-2541-9051">06-2541-9051</a>
            </div>
          </div>
        </div>
        <div
          class="right-nav"
          :style="{
            background: collapseStatus ? '#02033d' : '#000166',
          }"
          @click="toggleCollapse()"
        >
          <i class="fa-solid fa-bars"></i>
        </div>
      </el-header>
      <el-main :class="collapseStatus">
        <div class="tab-list">
          <router-link to="/" class="tab-option" @click="toggleCollapse()">
            <span> {{ $t("homePage") }}</span></router-link
          >

          <span class="tab-option mobile-foreign-tab">
            <!-- International Tours Collapse Component -->
            <el-collapse class="foreign-tour-collapse">
              <el-collapse-item :title="$t('internationalTours')">
                <div
                  class="collapse-item"
                  v-for="country in countryArray"
                  @click="navigateToList('', country.id)"
                  :key="country.id"
                  :name="country.id"
                >
                  <img
                    :src="country.image || require('../assets/no-image.svg')"
                    @error="
                      $event.target.src = require('../assets/no-image.svg')
                    "
                  />
                  <a>{{ country.title }} {{ $t("tour") }} </a>
                  <div class="quantity">
                    <a> {{ country.plan_total || 0 }}</a>
                  </div>
                </div>
              </el-collapse-item>
            </el-collapse>
          </span>

          <router-link
            to="/domestic_tour"
            class="tab-option"
            @click="toggleCollapse()"
          >
            <span>{{ $t("domesticTours") }}</span>
          </router-link>

          <router-link
            to="/domestic_tour"
            class="tab-option"
            @click="navigateToList('Promotional', '')"
          >
            <span>{{ $t("tourPromotion") }}</span>
          </router-link>

          <router-link
            to="/package_tour"
            class="tab-option"
            @click="toggleCollapse()"
          >
            <span>{{ $t("tourPackage") }}</span>
          </router-link>

          <router-link
            to="/airline_ticket"
            class="tab-option"
            @click="toggleCollapse()"
          >
            <span>{{ $t("bookFlightTicket") }}</span>
          </router-link>

          <!-- <router-link
            to="/admission_ticket"
            class="tab-option"
            @click="toggleCollapse()"
          >
            <span>{{ $t("admissionTicket") }}</span>
          </router-link> -->

          <router-link
            to="/cruise_list"
            class="tab-option"
            @click="toggleCollapse()"
          >
            <span>{{ $t("cruiseShip") }}</span>
          </router-link>

          <router-link
            to="/car_rental"
            class="tab-option"
            @click="toggleCollapse()"
          >
            <span>{{ $t("carRental") }}</span>
          </router-link>

          <!-- <router-link
            to="/article"
            class="tab-option"
            @click="toggleCollapse()"
          >
            <span>{{ $t("travelArticle") }}</span>
          </router-link> -->

          <router-link
            to="/about_us"
            class="tab-option"
            @click="toggleCollapse()"
          >
            <span>{{ $t("aboutUs") }}</span>
          </router-link>

          <router-link
            to="/past_work"
            class="tab-option"
            @click="toggleCollapse()"
          >
            <span>{{ $t("pastWork") }}</span>
          </router-link>

          <router-link
            to="/contact_us"
            class="tab-option"
            @click="toggleCollapse()"
          >
            <span>{{ $t("contactUs") }}</span>
          </router-link>

          <!-- <router-link
            v-if="!user || user == ''"
            to="/login"
            class="tab-option"
            @click="toggleCollapse()"
          >
            <span>{{ $t("login") }}</span>
          </router-link>

          <router-link
            v-if="user || user != ''"
            to="/account/profile"
            class="tab-option"
            @click="toggleCollapse()"
          >
            <span>{{ $t("myAccount") }}</span>
          </router-link>
          <router-link
            v-if="user || user != ''"
            to="/"
            class="tab-option"
            @click="mobileLogout()"
          >
            <span>{{ $t("logout") }}</span>
          </router-link> -->
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script setup>
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
// import { useI18n } from "vue-i18n";
// import axios from "axios";
// import config from "../api/config.json";

const collapseStatus = ref("");

const router = useRouter();
const store = useStore();
// const { t } = useI18n({ useScope: "global" });

const companyProfileImage = computed(
  () => store.getters.getCompanyProfileImage
);

// const user = computed(() => {
//   return store.getters.getUser;
// });
// const contactNumbers = computed(() => store.getters.getContactNumbers);
// const businessHour = computed(() => store.getters.getBusinessHour);
// const travelLicense = computed(() => store.getters.getTravelLicense);
// const socialMedia = computed(() => store.getters.getSocialMedia);
// const qualityTeamContacts = computed(
//   () => store.getters.getQualityTeamContacts
// );

/**
 * 区块化 国家选项目录 (一行十个国家)
 */
const chunkedCountryArray = computed(() => {
  const size = 10;
  let updatedArray = [];

  // 进行排列
  for (let i = 0; i < countryArray.value.length; i += size) {
    let chunk = countryArray.value.slice(i, i + size);
    updatedArray.push(chunk);
  }
  return updatedArray;
});

/**
 * 转移至 外国旅游 界面
 * @param tag 外国旅游 标签
 * @param countryId 外国旅游 ID
 */
const navigateToList = (tag, countryId) => {
  // 设置请求
  let queryParams = {};

  if (countryId && countryId != "") {
    queryParams.category = countryId || 1;
  }

  // 若要根据标签搜索
  if (tag && tag != "") {
    queryParams.tag = tag;
  }

  router.push({
    path: "/tour_list/search",
    query: queryParams,
  });
  toggleCollapse();
  window.scrollTo(0, 0);
};

/**
 *  打开折叠图版
 */
const toggleCollapse = () => {
  if (!collapseStatus.value) {
    collapseStatus.value = "collapsing";

    setTimeout(() => {
      collapseStatus.value = "collapsed";
    }, 300);
  } else {
    collapseStatus.value = "collapsing";

    setTimeout(() => {
      collapseStatus.value = "";
    }, 50);
  }
};

/**
 *  从store获取国家列表
 */
const countryArray = computed(() => {
  return store.getters.getCountryList;
});

/**
 * 账号登出
 */
// const logout = async () => {
//   await axios
//     .post(`${config.apiBaseUrl}/${config.version}/customer/logout`)
//     .then(() => {
//       store.dispatch("logout");
//       window.alert(t("logoutSuccess"));
//     })
//     .catch((error) => {
//       // 发生错误
//       console.error("Logout Error:", error);
//       window.alert(t("logoutFail"));
//     });
// };

// const mobileLogout = async () => {
//   await axios
//     .post(`${config.apiBaseUrl}/${config.version}/customer/logout`)
//     .then(() => {
//       store.dispatch("logout");
//       toggleCollapse();
//       window.alert(t("logoutSuccess"));
//     })
//     .catch((error) => {
//       // 发生错误
//       console.error("Logout Error:", error);
//       window.alert(t("logoutFail"));
//     });
// };
</script>

<style scoped>
/* Display of Navigation */
.web-view {
  display: block;

  @media (max-width: 991px) {
    display: none;
  }
}

.mobile-view {
  display: none;

  @media (max-width: 991px) {
    display: block;
  }
}

/* TOP NAV */
.top-nav {
  display: block;
  width: 100%;
  height: 45px;
  background: #000166;
  padding-top: 10px;
}

.top-nav .container {
  width: 1170px;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
  color: #fff;
  text-align: right;

  @media (min-width: 991px) and (max-width: 1260px) {
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.top-nav a {
  color: #fff;
  line-height: 30px;
  font-size: 1em;
  text-decoration: none;
  cursor: pointer;
}

/* MAIN NAV */
.main-nav {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.main-nav .container {
  width: 1170px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 991px) and (max-width: 1260px) {
    width: 100%;
  }
}

/* CONTACT */
.contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hotline {
  display: flex;
  padding-left: 10px;
}

.hotline-info {
  display: flex;
  flex-direction: column;
  position: relative;
  width: auto;
  height: auto;
  padding-left: 10px;
}

.hotline-info a {
  color: black;
  line-height: 20px;
  text-decoration: none;
}

/* LOGO */
.box--head-img-txt {
  position: relative;
  height: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.box--head-img-txt .box--hlogo {
  position: relative;
  width: auto;
  height: auto;
}

.box--head-img-txt .box--hlogo a {
  position: relative;
  width: 100%;
  height: 100%;
}

.box--head-img-txt .box--hlogo figure {
  padding: 0 !important;
  font-size: 0;
  height: 120px;
  width: auto;
  max-width: 420px;
}

.box--head-img-txt .box--hlogo figure .size--img-lg {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: 0;
}

.detail-logo {
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  padding-right: 10px;
  border-right: 1px solid #ddd;
}

.detail-logo i {
  font-size: 30px;
  padding: 10px;
}

/* DETAIL */
.detail-panel {
  position: relative;
  width: auto;
  height: auto;
  padding-left: 16px;
  padding-right: 16px;
}

/* MENU */
.menu-container {
  border-top: solid 1px #ddd;
  border-bottom: solid 1px #ddd;
}

router-link {
  text-decoration: none;
}

.menu {
  display: flex;
}

.menu-container ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.menu-container ul.menu li {
  width: 100%;
  border-left: solid 1px #ddd;
  font-weight: bold;
}

.menu-container ul.menu li.tab {
  width: 15%;
  display: flex;
  align-items: center;
  border-left: solid 1px #ddd;
  font-weight: bold;
}

.menu-container ul > li:hover,
.chaser ul.menu > li:hover > a {
  cursor: pointer;
  color: #12a0c3;
}

.nav-route {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;

  @media (min-width: 991px) and (max-width: 1260px) {
    font-size: 0.9em;
  }
}

/* Hover Tab */
.foreign-tab:hover .hover-panel {
  display: block;
}

.hover-panel {
  display: none;
  position: absolute;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 2;

  @media (max-width: 1550px) {
    width: 800px !important;
    overflow-x: scroll;
  }
}

.foreign {
  left: 22%;
  top: 26%;

  @media (min-width: 991px) and (max-width: 1400px) {
    left: 10%;
  }
}

.hover-menu {
  list-style-type: none;
  margin: 0;
  display: flex;
  flex-direction: row;
}

.hover-menu .hover-list {
  padding: 0 !important;
}

.hover-card {
  width: 160px !important;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px !important;
  opacity: 0.7;
  border: 1px solid #f5f5f5;
}

.hover-card:hover {
  opacity: 1;
  background-color: #e2dbee;
}

.hover-card a {
  color: #333;
  font-size: 0.8em;
  text-decoration: none;
  text-align: center;
}

.hover-card img {
  width: 22px;
  border-radius: 20px;
}

.hover-card .quantity {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000166;
  border-radius: 50px;
}

.quantity a {
  color: white;
}

/* Mobile View Top Nav */
.mobile-view .el-header {
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 0;
  border-bottom: solid 1px #ddd;
}

.left-nav {
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.left-nav img {
  padding: 10px;
  width: 70px;
}

.left-nav .hotline {
  width: 60%;
  padding-left: 0;
  padding-right: 10px;
}

.left-nav .detail-logo {
  height: 100%;
  display: flex;
  align-items: center;
}

.left-nav .detail-logo i {
  font-size: 1.5em;
}

.left-nav .hotline a {
  font-size: 0.5em;
  line-height: 20px;
}

.right-nav {
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.7em;
  color: white;
  transition: background-color 0.35s ease;
}

/* Mobile View Nav Body */
.mobile-view .el-main {
  position: relative;
  height: 0;
  padding: 0;
  border-bottom: solid 1px #ddd;
  transition: height 0.35s ease;
}

.collapsing {
  /* height: 750px !important; */
  height: 640px !important;
}

.collapsed {
  height: auto !important;
}

.mobile-view .tab-list {
  display: flex;
  flex-direction: column;
}

.mobile-view .tab-list .tab-option {
  padding: 20px 0;
  padding-left: 5px;
  margin: 0 15px;
  font-size: 0.9em;
  font-weight: bold;
  border-bottom: solid 1px #171053ad;
  text-decoration: 0;
  color: rgba(0, 0, 0, 0.533);
}

.mobile-view .tab-list .tab-option::v-deep a {
  text-decoration: 0;
}

.mobile-view .tab-list .tab-option:hover {
  color: rgb(18, 160, 195);
}

/* Collapse Item ( Foreign Tour ) ( Mobile ) */
.foreign-tour-collapse .collapse-list {
  display: none;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 991px) {
    display: flex;
  }
}

.mobile-foreign-tab {
  padding: 0 !important;
}

.foreign-tour-collapse::v-deep .el-collapse-item__header {
  height: 57px;
  padding-left: 5px;
  color: rgba(0, 0, 0, 0.533);
  font-size: 1em;
  font-weight: bold;
}

.foreign-tour-collapse::v-deep .el-collapse-item__header.is-active {
  color: rgb(9, 0, 42);
}

.collapse-item {
  width: 90%;
  margin: 0 15px;
  padding: 15px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: rgba(0, 0, 0, 0.533);
  border-top: solid 1px #ddd;
  cursor: pointer;
}

.collapse-item a {
  color: rgba(0, 0, 0, 0.533) !important;
}

.collapse-item:hover a {
  color: rgba(0, 0, 0) !important;
}

.collapse-item img {
  width: 40px;
  border-radius: 100px;
  margin: 0 15px;
}

.collapse-item .quantity {
  width: 25px;
  height: 25px;
  display: flex;
  margin-right: 15px;
  margin-left: auto;
  justify-content: center;
  align-items: center;
  background: #3e085ba4;
  border-radius: 50px;
}

.collapse-item .quantity a {
  color: rgb(255, 255, 255) !important;
}
</style>

<template>
  <div>
    <el-container>
      <!-- Header -->
      <el-header class="top-nav">
        <div class="container">
          <h3>{{ $t("aboutUs") }}</h3>
          <div class="link">
            <a class="router-link" style="margin-right: 5px"
              ><router-link class="nav-route" to="/">{{
                $t("homePage")
              }}</router-link></a
            >
            /
            <a style="margin-left: 5px">{{ $t("aboutUs") }}</a>
          </div>
        </div>
      </el-header>

      <!-- Body -->
      <el-main class="main-content">
        <div class="container">
          <div class="info company">
            <div class="pic-panel">
              <img
                :src="companyProfileImage"
                @error="$event.target.src = require('../assets/no-image.svg')"
              />
            </div>
            <div class="info-panel company-info">
              <h3>
                <i class="fa-solid fa-building-columns"></i>
                <!-- {{ $t("companyName") }} -->
                {{ companyName }}
              </h3>
              <!-- {{ $t("companyName") }} -->
              <h4 style="margin-bottom: 10px">{{ $t("companyInfo") }}</h4>
              <a>{{ $t("companyInfoData") }}</a>
            </div>
          </div>

          <div class="info service">
            <div class="info-panel service-info">
              <h3>
                <i class="fa-solid fa-circle-exclamation"></i
                >{{ $t("coreService") }}
              </h3>
              <h4 style="margin-bottom: 10px">{{ $t("serviceInfo") }}</h4>
              <a>{{ $t("serviceInfoData") }}</a>
            </div>
            <div class="pic-panel">
              <img
                src="../assets/license.jpg"
                @error="$event.target.src = require('../assets/no-image.svg')"
              />
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
export default {
  name: "App",

  computed: {
    // 取得store里面的数据
    companyName() {
      return this.$store.getters.getSiteName;
    },
    companyProfileImage() {
      return this.$store.getters.getCompanyProfileImage;
    },
  },
};
</script>
<style scoped>
/* Top Nav */
.top-nav {
  display: block;
  width: 100%;
  height: 56px;
  background: #2d3e52;
}

.top-nav .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1170px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  color: white;

  @media (min-width: 991px) and (max-width: 1260px) {
    width: 100%;
  }

  @media (max-width: 991px) {
    width: 100%;
  }
}

.top-nav .link {
  @media (max-width: 991px) {
    display: none;
  }
}

.top-nav h3 {
  font-weight: 300 !important;
}

.top-nav a {
  color: #fdb714;
  font-size: 1em;
  font-weight: bold;
  text-decoration: none;
}

router-link {
  text-decoration: none;
}

.top-nav .router-link:hover {
  color: #12a0c3;
}

/* Body */
.main-content {
  display: block;
  width: 100%;
  padding: 0px;
  background: #f5f5f5;

  @media (max-width: 991px) {
    width: auto;
  }
}

.main-content .container {
  width: 1170px;
  padding: 16px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 991px) and (max-width: 1260px) {
    width: 95%;
  }

  @media (max-width: 991px) {
    width: auto;
    padding: 20px 5px;
  }
}

.info {
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  border-radius: 2px;
  margin: 20px 0;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.15);

  @media (max-width: 991px) {
    flex-direction: column;
  }
}

.service {
  @media (max-width: 991px) {
    flex-direction: column-reverse !important;
  }
}

.pic-panel {
  width: 35%;
  margin: 25px;

  @media (max-width: 991px) {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
}

.pic-panel img {
  width: 90%;
}

.info-panel {
  width: 65%;
  padding: 20px;
  padding-right: 50px;
  @media (max-width: 991px) {
    padding-bottom: 50px !important;
  }
}

.company-info {
  @media (max-width: 991px) {
    padding: 20px 50px;
  }
}

.service-info {
  padding: 20px 50px;
}

.info-panel a {
  line-height: 25px !important;
}

h3 i {
  margin-right: 10px;
}
</style>

<template>
  <div class="main">
    <div class="content-body">
      <el-container class="container">
        <!-- Side Bar -->
        <el-aside class="side-bar">
          <a class="title">{{ $t("myAccount") }}</a>
          <router-link
            to="/account/profile"
            class="options profile"
            :class="currentRoute === `/account/profile` ? 'selected' : ''"
            >{{ $t("profile") }} <i class="fa-solid fa-user"></i
          ></router-link>
          <!-- <router-link
            to="/account/reset_password"
            class="options"
            :class="
              currentRoute === `/account/reset_password` ? 'selected' : ''
            "
            >{{ $t('resetPassword') }}
<i class="fa-solid fa-key"></i
          ></router-link> -->
          <!-- <router-link
            to="/account/orders"
            class="options"
            :class="currentRoute === `/account/orders` ? 'selected' : ''"
            >{{ $t("myOrder") }} <i class="fa-solid fa-clipboard-list"></i
          ></router-link> -->
          <router-link to="/" class="options" @click="logout()"
            >{{ $t("logout") }}
            <i class="fa-solid fa-arrow-right-from-bracket"></i
          ></router-link>
        </el-aside>
        <!-- Sub Page -->
        <el-main class="main-content">
          <Transition name="fade">
            <router-view></router-view>
          </Transition>
        </el-main>
      </el-container>
    </div>
  </div>
</template>
<script setup>
import axios from "axios";
import config from "../../api/config.json";

import { ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

const route = useRoute();
const store = useStore();
const currentRoute = ref(route.path);
const { t } = useI18n({ useScope: "global" });

/**
 * 账号登出
 */
const logout = async () => {
  await axios
    .post(`${config.apiBaseUrl}/${config.version}/customer/logout`)
    .then(() => {
      store.dispatch("logout");
      window.alert(t("logoutSuccess"));
    })
    .catch((error) => {
      // 发生错误
      console.error("Logout Error:", error);
      window.alert(t("logoutFail"));
    });
};
</script>
<style scoped>
/* Main */
.main {
  background: rgb(245, 245, 245);
}
/* Content */
.content-body {
  width: 1170px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  background: rgb(245, 245, 245);

  @media (min-width: 991px) and (max-width: 1260px) {
    width: 100%;
  }

  @media (max-width: 991px) {
    width: auto;
  }
}
/* Container */
.container {
  min-height: 950px;

  @media (max-width: 991px) {
    flex-direction: column;
  }
}

/* Side Bar */
.side-bar {
  width: 300px;
  padding-top: 50px;
  padding-bottom: auto;
  display: flex;
  flex-direction: column;
  background-color: rgb(239, 240, 249);
  border: 1px solid rgba(158, 158, 158, 0.05);

  @media (max-width: 991px) {
    width: 100%;
    flex-direction: row;
    padding-top: 0;
  }
}

.title {
  font-size: 1.8em;
  font-weight: bold;
  margin-bottom: 50px;
  padding-left: 20px;
  color: rgb(0, 0, 0);

  @media (max-width: 991px) {
    display: none;
    margin-bottom: 0;
    padding-left: 0;
  }
}

.profile {
  border-top: 1px solid rgba(128, 128, 128, 0.2);

  @media (max-width: 991px) {
    border-top: none;
    border-left: 1px solid rgba(128, 128, 128, 0.2);
  }
}

.options {
  display: flex;
  justify-content: space-between;
  padding: 20px 15px;
  background-color: rgb(239, 240, 249);
  font-size: 1.1em;
  font-weight: bold;
  color: rgb(123, 123, 123);
  border-bottom: 1px solid rgba(128, 128, 128, 0.2);
  text-decoration: none;
  transition: background-color 1s linear;

  @media (max-width: 991px) {
    padding: 20px 25px;
    border-bottom: none;
    border-right: 1px solid rgba(128, 128, 128, 0.2);

    i {
      margin-left: 10px;
    }
  }
}

.options:hover {
  color: rgb(26, 26, 123);
}

.selected {
  background-color: white;
  color: rgb(26, 26, 123);
  border-left: 3px solid rgb(26, 26, 123);

  @media (max-width: 991px) {
    border-left: none;
  }
}

/* Main Content */
.main-content {
  height: auto;
  padding: 0;
  background: rgb(255, 255, 255);
  overflow: hidden;
}

/* Animation */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

<template>
  <div v-if="loading" class="loading-screen">
    <i class="fa-solid fa-spinner"></i>
  </div>
  <div v-else>
    <el-container>
      <!-- Header -->
      <el-header class="top-nav">
        <div class="container">
          <div>
            <h3 v-if="$route.query.tag">
              {{
                $route.query.tag
                  .split("_")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")
              }}
            </h3>
            <h3
              v-if="
                !$route.query.tag &&
                ($route.query.keyword ||
                  $route.query.period ||
                  $route.query.province)
              "
            >
              {{ $t("searchTerm") }}: {{ $route.query.keyword }}
              {{ $route.query.period }}
              {{ $route.query.province }}
            </h3>
          </div>
          <div class="nav-title">
            <a class="router-link" style="margin-right: 5px"
              ><router-link class="nav-route" to="/">{{
                $t("homePage")
              }}</router-link></a
            >
            /
            <a v-if="$route.query.tag" style="margin-left: 5px">{{
              $route.query.tag
                .split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")
            }}</a>
            <a v-else>{{ $t("tourPackage") }}</a>
          </div>
        </div>
      </el-header>
      <el-main class="main-content">
        <div class="container">
          <!-- Search Result -->
          <div class="searchResult">
            <a>{{ $t("found") }} {{ totalCount }} {{ $t("program") }}</a>
          </div>
          <!-- Result List -->
          <div class="package-list">
            <div
              class="item"
              v-for="(packageItem, index) in packageArray"
              :key="index"
            >
              <PackageItem
                :code="packageItem.code"
                :id="packageItem.id"
                :travelBy="packageItem.travel_by"
                :title="packageItem.title"
                :images="packageItem.images"
                :duration="packageItem.number_of_days"
                :startDate="packageItem.start_date"
                :endDate="packageItem.end_date"
                :price="packageItem.price_per_person"
              />
            </div>
          </div>
        </div>
      </el-main>
      <el-footer>
        <!-- Pagination -->
        <div class="pagination">
          <button
            class="previous-button"
            @click="previousPage"
            :disabled="currentPage === 1"
          >
            {{ $t("previousPage") }}
          </button>
          <div v-for="(pageNumber, index) in visiblePages" :key="pageNumber">
            <a
              style="font-weight: bold"
              v-if="index !== 0 && visiblePages[index - 1] !== pageNumber - 1"
              >...</a
            >
            <span
              :class="
                'page-button' + [currentPage == pageNumber ? ' disabled' : '']
              "
              v-if="totalPages > 1"
              :key="pageNumber"
              @click="gotoPage(pageNumber)"
              :disabled="currentPage === pageNumber"
            >
              {{ pageNumber }}</span
            >
          </div>
          <span class="page-button disabled" v-if="totalPages < 2">1</span>
          <button
            class="next-button"
            @click="nextPage"
            :disabled="currentPage === totalPages || totalPages === 0"
          >
            {{ $t("nextPage") }}
          </button>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
import PackageItem from "../../components/item/packageItem.vue";

import axios from "axios";
import config from "../../api/config.json";

export default {
  name: "App",
  components: {
    PackageItem,
  },
  data: function () {
    return {
      itemsPerPage: 8,
      currentPage: 1,
      totalCount: 0,

      loading: true,

      packageArray: [],
    };
  },
  mounted() {
    this.fetchPackageData();
  },
  computed: {
    /**
     * 获取计算后的页面总数
     */
    totalPages() {
      return Math.ceil(this.packageArray.length / this.itemsPerPage);
    },

    /**
     * 获取当前显示的页面号码
     */
    visiblePages() {
      const totalPages = this.totalPages;
      const currentPage = this.currentPage;

      let visiblePages = [];
      visiblePages.push(1);

      for (let i = 1; i <= totalPages; i++) {
        if (currentPage == i && !visiblePages.includes(i)) {
          visiblePages.push(i);
        } else if (i === totalPages && !visiblePages.includes(i)) {
          visiblePages.push(i);
        } else if (i === currentPage - 1 && !visiblePages.includes(i)) {
          visiblePages.push(i);
        } else if (i === currentPage + 1 && !visiblePages.includes(i)) {
          visiblePages.push(i);
        }
      }

      return visiblePages;
    },
  },
  methods: {
    /**
     * 获取 旅游配套 数据
     */
    async fetchPackageData() {
      let queryParams = {
        category: 3,
        page: this.currentPage,
        limit: this.itemsPerPage,
      };
      // 显示加载中
      this.loading = true;

      // 获取旅游配套数据
      await axios
        .get(`${config.apiBaseUrl}/${config.version}/plan`, {
          params: queryParams,
        })
        .then((response) => {
          this.packageArray = response.data.data;
          this.totalCount = response.data.count;
        })
        .catch((error) => {
          // 发生错误
          console.error("Error fetching packages:", error);
        })
        .finally(() => {
          // 执行就结束，结束加载中
          this.loading = false;
        });
    },

    /**
     * 显示下个页面的物件
     */
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.fetchPackageData();
        window.scrollTo(0, 0);
      }
    },

    /**
     * 显示上个页面的物件
     */
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchPackageData();
        window.scrollTo(0, 0);
      }
    },

    /**
     * 移动至选定页面
     */
    gotoPage(pageNumber) {
      this.currentPage = pageNumber;
      this.fetchPackageData();
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style scoped>
/* Top Nav */
.top-nav {
  display: block;
  width: 100%;
  height: 56px;
  background: #2d3e52;

  @media (max-width: 991px) {
    width: auto;
  }
}

.top-nav .container {
  display: flex;
  align-items: center;
  width: 1170px;
  height: 100%;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  color: #5a7ca3;
  font-weight: bold;
  @media (min-width: 991px) and (max-width: 1260px) {
    width: 100%;
  }

  @media (max-width: 991px) {
    width: auto;
  }
}

.top-nav a {
  color: #fdb714;
  font-size: 1em;
  text-decoration: none;
}

.top-nav h3 {
  color: #fff;
  font-weight: 300;
  text-decoration: none;
}

router-link {
  text-decoration: none;
}

.top-nav .router-link:hover {
  color: #12a0c3;
}

.nav-title {
  display: block;
  @media (max-width: 991px) {
    display: none;
  }
}

/* Body */
.main-content {
  display: block;
  width: 100%;
  background: #f5f5f5;

  @media (max-width: 991px) {
    width: auto;
  }
}

.main-content .container {
  width: 1170px;
  padding: 16px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 991px) and (max-width: 1260px) {
    width: 95%;
  }

  @media (max-width: 991px) {
    width: auto;
    padding: 20px 5px;
  }
}

.searchResult {
  margin-bottom: 30px;
  padding-left: 30px;
  background: white;
  border: solid 1px #ddd;
  border-radius: 8px;
}

.searchResult a {
  line-height: 50px;
}

/* Package List */
.package-list {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: stretch;
  flex-wrap: wrap;
  flex-direction: row;

  @media (max-width: 991px) {
    flex-direction: column;
  }
}

.item {
  width: calc((100% / 4));

  @media (max-width: 991px) {
    width: 100%;
  }
}

/* Pagination */
.el-footer {
  background: #f5f5f5;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0 20px 0;
  font-weight: bold;
  background: #f5f5f5;
}

.pagination button {
  padding: 12px;
  color: #fff;
  font-size: 0.95em;
  font-weight: bold;
  background: #000166;
  border: none;
  cursor: pointer;
}

.pagination button:disabled {
  color: #fff;
  background: #d9d9d9;
  border: none;
  cursor: context-menu;
}

.previous-button {
  margin-right: 5px;
}

.next-button {
  margin-left: 5px;
}

.pagination .page-button {
  margin: 0 5px 0 5px;
  padding: 12px;
  color: #fff;
  background: #d9d9d9;
  font-size: 0.95em;
  text-align: center;
  border: none;
  cursor: context-menu;
}

.pagination .disabled,
.pagination .page-button:hover {
  background: #000166;
}

/* Loading */
.loading-screen {
  width: 100%;
  height: 300px;
  text-align: center;
  padding-top: 50px;
}

.loading-screen i {
  color: #828282;
  font-size: 3em;

  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

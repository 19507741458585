<template>
  <div v-if="loading" class="loading-screen">
    <i class="fa-solid fa-spinner"></i>
  </div>
  <div v-else>
    <el-container>
      <!-- Header -->
      <el-header class="top-nav">
        <div class="container">
          <a class="router-link" style="margin-right: 5px"
            ><router-link class="nav-route" to="/">{{
              $t("homePage")
            }}</router-link></a
          >
          /
          <a class="router-link" style="margin: 0 5px"
            ><router-link class="nav-route" to="/package_tour">{{
              $t("tourPackage")
            }}</router-link></a
          >
          /
          <a style="margin-left: 5px">{{ packageObject.title }}</a>
        </div>
      </el-header>

      <!-- Body -->
      <el-main class="main-content">
        <div class="container">
          <!-- Title -->
          <div class="title">
            <h1>{{ packageObject.title }}</h1>
            <img
              :src="
                packageObject.images || require('../../assets/no-image.svg')
              "
              @error="$event.target.src = require('../../assets/no-image.svg')"
            />
          </div>
          <!-- Tab -->
          <div class="tab-list">
            <div class="tab-options">
              <span
                :class="[tabPage == 1 ? ' disabled' : '']"
                @click="switchTab(1)"
              >
                {{ $t("tourProgram") }}
              </span>
              <span
                :class="[tabPage == 2 ? ' disabled' : '']"
                @click="switchTab(2)"
              >
                {{ $t("serviceRate") }}
              </span>
              <span
                :class="[tabPage == 3 ? ' disabled' : '']"
                @click="switchTab(3)"
              >
                {{ $t("bookNPayment") }}
              </span>
              <span
                v-if="
                  (packageObject.notice1 && packageObject.notice1.length > 0) ||
                  (packageObject.notice2 && packageObject.notice2.length > 0)
                "
                :class="[tabPage == 4 ? ' disabled' : '']"
                @click="switchTab(4)"
              >
                {{ $t("condition") }}
              </span>
            </div>
          </div>
          <!-- Tour Program -->
          <Transition name="fade" v-on:after-leave="afterLeave">
            <div v-if="tabPage == 1 && displayTab == 1" class="package-program">
              <div class="program-info">
                <div class="package-image">
                  <img
                    :src="
                      packageObject.images ||
                      require('../../assets/no-image.svg')
                    "
                    @error="
                      $event.target.src = require('../../assets/no-image.svg')
                    "
                  />
                </div>
                <div class="package-info">
                  <div class="info">
                    <a><i class="fa-solid fa-tags"></i>{{ $t("tourCode") }}</a>
                    <a class="info-detail">{{ packageObject.code }}</a>
                  </div>
                  <div class="info">
                    <a
                      ><i class="fa-regular fa-clock"></i
                      >{{ $t("travelDays") }}</a
                    >
                    <a class="info-detail">{{
                      packageObject.number_of_days
                    }}</a>
                  </div>
                  <div class="info">
                    <a
                      ><i class="fa-regular fa-calendar-days"></i
                      >{{ $t("travelSchedule") }}</a
                    >
                    <a class="info-detail"
                      >{{ formatDate(packageObject.start_date) }} -
                      {{ formatDate(packageObject.end_date) }}</a
                    >
                  </div>
                  <div class="info">
                    <a
                      ><i class="fa-solid fa-paper-plane"></i
                      >{{ $t("travelBy") }}</a
                    >
                    <img
                      :src="
                        packageObject.travel_by ||
                        require('../../assets/no-image.svg')
                      "
                      @error="
                        $event.target.src = require('../../assets/no-image.svg')
                      "
                    />
                  </div>
                  <div class="info" style="padding-bottom: 15px">
                    <a
                      ><i class="fa-solid fa-coins"></i
                      >{{ $t("startingPrice") }}</a
                    >
                    <div class="price">
                      <h1>
                        {{
                          parseFloat(
                            packageObject.price_per_person
                          ).toLocaleString("en-US")
                        }}
                      </h1>
                      <a>{{ $t("bahtPerPerson") }}</a>
                    </div>
                  </div>
                  <!-- Contact -->
                  <div class="button-list">
                    <button
                      class="call-button"
                      @click="navigateTo(`tel:${this.phoneNumber}`)"
                    >
                      <i class="fa-solid fa-phone"></i>{{ $t("callReserve") }}
                    </button>
                    <button
                      class="line-button web-line"
                      @click="showReserveModal = true"
                    >
                      <i class="fa-brands fa-line"></i>{{ $t("bookOnline") }}
                    </button>
                    <button
                      class="line-button mobile-line"
                      @click="moveToReserveLink()"
                    >
                      <i class="fa-brands fa-line"></i>{{ $t("bookOnline") }}
                    </button>
                    <button
                      v-if="packageObject.document"
                      class="view-button"
                      @click="navigateTo(packageObject.document)"
                    >
                      <i class="fa-regular fa-file-pdf"></i
                      >{{ $t("viewProgram") }}
                    </button>
                  </div>
                </div>
              </div>
              <!-- Highlight -->
              <div v-if="packageObject.long_description" class="highlight-box">
                <h3><i class="fa-solid fa-star"></i>{{ $t("hightlight") }}</h3>
                <a v-html="decodeHtml(packageObject.long_description)"></a>
              </div>
              <!-- Program Detail -->
              <div
                v-if="
                  packageObject.programs && packageObject.programs.length > 0
                "
                class="program-detail"
              >
                <h1>{{ $t("programDetail") }}</h1>
                <div
                  class="detail-item"
                  v-for="(detail, index) in packageObject.programs"
                  :key="index"
                >
                  <div class="detail-index">
                    <a>{{ $t("day") }}</a>
                    <a>{{ index + 1 }}</a>
                  </div>
                  <i class="fa-solid fa-arrow-right"></i>
                  <a class="detail-info">
                    {{ detail }}
                  </a>
                </div>
              </div>
            </div>
          </Transition>
          <!-- Service Rate-->
          <Transition name="fade" v-on:after-leave="afterLeave">
            <div v-if="tabPage == 2 && displayTab == 2" class="service-rate">
              <table>
                <!-- Service Rate Table Title -->
                <thead>
                  <tr>
                    <th>{{ $t("hotel") }}</th>
                    <th>8 {{ $t("people") }}</th>
                    <th>6 {{ $t("people") }}</th>
                    <th>4 {{ $t("people") }}</th>
                    <th>{{ $t("status") }}</th>
                  </tr>
                </thead>
                <!-- Service Rate Table Body -->
                <tbody>
                  <tr
                    class="table-item"
                    v-for="hotel in packageObject.serviceRate"
                    :key="hotel.name"
                  >
                    <td class="name">{{ hotel.hotel }}</td>
                    <td class="price">
                      {{ getPriceByPeople(hotel, 8).toLocaleString("en-US") }}
                    </td>
                    <td class="price">
                      {{ getPriceByPeople(hotel, 6).toLocaleString("en-US") }}
                    </td>
                    <td class="price">
                      {{ getPriceByPeople(hotel, 4).toLocaleString("en-US") }}
                    </td>
                    <td class="reserve">
                      <button
                        class="collapse-reserve"
                        @click="showReserveModal = true"
                      >
                        {{ $t("reserveNow") }}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- Date And Price Collapse Component -->
              <el-collapse class="mobile-collapse" accordion>
                <el-collapse-item
                  v-for="hotel in packageObject.serviceRate"
                  :key="hotel.hotel"
                  :title="hotel.hotel"
                  :name="hotel.hotel"
                >
                  <div class="collapse-list" v-if="getPriceByPeople(hotel, 8)">
                    <div class="collapse-item">8 {{ $t("people") }}</div>
                    <div class="collapse-value">
                      {{ getPriceByPeople(hotel, 8).toLocaleString("en-US") }}
                      {{ $t("baht") }}
                    </div>
                  </div>
                  <div class="collapse-list" v-if="getPriceByPeople(hotel, 6)">
                    <div class="collapse-item">6 {{ $t("people") }}</div>
                    <div class="collapse-value">
                      {{ getPriceByPeople(hotel, 6).toLocaleString("en-US") }}
                      {{ $t("baht") }}
                    </div>
                  </div>
                  <div class="collapse-list" v-if="getPriceByPeople(hotel, 4)">
                    <div class="collapse-item">4 {{ $t("people") }}</div>
                    <div class="collapse-value">
                      {{ getPriceByPeople(hotel, 4).toLocaleString("en-US") }}
                      {{ $t("baht") }}
                    </div>
                  </div>
                  <div class="reserve">
                    <button
                      class="collapse-call"
                      @click="navigateTo(`tel:${this.phoneNumber}`)"
                    >
                      {{ $t("callReserve") }}
                    </button>
                    <button
                      class="collapse-reserve"
                      @click="moveToReserveLink()"
                    >
                      {{ $t("reserveNow") }}
                    </button>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </Transition>
          <!-- Booking Tutorial -->
          <Transition name="fade" v-on:after-leave="afterLeave">
            <div v-if="tabPage == 3 && displayTab == 3" class="booking">
              <PaymentTutorial></PaymentTutorial>
            </div>
          </Transition>
          <!-- Condition -->
          <Transition name="fade" v-on:after-leave="afterLeave">
            <div v-if="tabPage == 4 && displayTab == 4" class="condition">
              <div class="notice-box include">
                <h3>
                  <i class="fa-solid fa-check"></i>{{ $t("rateInclude") }}
                </h3>
                <ul>
                  <li
                    v-for="(noticeItem, index) in packageObject.notice1"
                    :key="index"
                  >
                    {{ noticeItem }}
                  </li>
                </ul>
              </div>
              <div class="notice-box">
                <h3>
                  <i class="fa-solid fa-xmark"></i>{{ $t("rateNotInclude") }}
                </h3>
                <ul>
                  <li
                    v-for="(noticeItem, index) in packageObject.notice2"
                    :key="index"
                  >
                    {{ noticeItem }}
                  </li>
                </ul>
              </div>
            </div>
          </Transition>
          <!-- Social Media -->
          <div class="social-media">
            <div class="social-list">
              <!-- Facebook -->
              <a
                :href="`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                  currentLink
                )}&display=popup&ref=plugin&src=like`"
              >
                <i
                  class="fa-brands fa-facebook social-img"
                  alt="Share on Facebook"
                ></i>
              </a>
              <!-- Messenger -->
              <a
                :href="`https://www.messenger.com/sharer.php?u=${encodeURIComponent(
                  currentLink
                )}`"
              >
                <i
                  class="fa-brands fa-facebook-messenger social-img"
                  alt="Share on Messenger"
                ></i>
              </a>
              <!-- Line -->
              <a
                :href="`https://line.me/R/msg/text/?${encodeURIComponent(
                  currentLink
                )}`"
              >
                <i class="fa-brands fa-line social-img" alt="Share on Line"></i>
              </a>
              <!-- Email -->
              <a
                :href="`mailto:?subject=&body=${encodeURIComponent(
                  currentLink
                )}`"
              >
                <i
                  class="fa-regular fa-envelope social-img"
                  alt="Share on Email"
                ></i>
              </a>
              <!-- Twitter -->
              <a
                :href="`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                  currentLink
                )}`"
              >
                <i
                  class="fa-brands fa-x-twitter social-img"
                  alt="Share on Twitter"
                ></i>
              </a>
            </div>
            <div class="link">
              <input
                ref="inputField"
                type="text"
                :value="currentLink"
                class="input-field"
                @click="copyToClipboard"
              />
              <button class="copy-button" @click="copyToClipboard">
                <i class="fa-solid fa-link" style="padding-right: 5px"></i
                >{{ $t("copy") }}
              </button>
            </div>
            <div
              class="link document-link"
              v-if="
                packageObject.document &&
                packageObject.document.length > 0 &&
                packageObject.document[0]
              "
            >
              <input
                ref="documentField"
                type="text"
                :value="packageObject.document"
                class="input-field"
                @click="copyDocumentToClipboard"
              />
              <button
                class="copy-button document-button"
                @click="copyDocumentToClipboard"
              >
                <i class="fa-solid fa-file-pdf" style="padding-right: 5px"></i
                >{{ $t("copy") }}
              </button>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
    <div class="similar-program">
      <div class="container">
        <SimilarPackage
          :id="packageObject.id"
          :category="packageObject.category_id"
        >
        </SimilarPackage>
      </div>
    </div>
  </div>
  <!-- Reserve Modal -->
  <Transition name="modal">
    <ReserveModal
      class="modal"
      v-if="showReserveModal"
      :reserveLink="`https://line.me/R/ti/p/%40${line}`"
      @closeModal="closeReserveModal"
    ></ReserveModal>
  </Transition>
</template>
<script>
import axios from "axios";
import config from "../../api/config.json";

import { getCurrentInstance } from "vue";
import PaymentTutorial from "../../components/paymentTutorial.vue";
import ReserveModal from "../../components/reserveModal.vue";
import SimilarPackage from "../../components/similar/similarPackage.vue";

export default {
  name: "App",
  components: {
    PaymentTutorial,
    ReserveModal,
    SimilarPackage,
  },
  data: function () {
    return {
      currentLink: window.location.href,
      phoneNumber: "06-2158-2884",
      tabPage: 1, // 当前页面，原始值为1，即 配套资料 页面
      displayTab: 1,
      showReserveModal: false,
      loading: true,

      // 旅游配套 样本数据
      packageObject: {},

      moment: getCurrentInstance().appContext.config.globalProperties.$moment,
    };
  },
  mounted() {
    this.fetchPackageData();
  },
  computed: {
    line() {
      return this.$store.getters.getCompanyLineId;
      // return "gtq9153t";
    },
  },
  methods: {
    /**
     * 获取 旅游配套 数据
     */
    async fetchPackageData() {
      // 显示加载中
      this.loading = true;

      // 获取旅游配套数据
      await axios
        .get(
          `${config.apiBaseUrl}/${config.version}/plan/${this.$route.params.id}`
        )
        .then((response) => {
          this.getPackageData(response.data);
        })
        .catch((error) => {
          // 发生错误
          console.error("Error fetching packages:", error);
        })
        .finally(() => {
          // 执行就结束，结束加载中
          this.loading = false;
          document.title = this.packageObject.title;
        });
    },

    /**
     * 将API获取到的资料放在packageObject
     * @param packageResponse
     */
    getPackageData(packageResponse) {
      let processedObject = {};

      for (let key in packageResponse) {
        if (Object.prototype.hasOwnProperty.call(packageResponse, key)) {
          let value = packageResponse[key];

          // 添加data
          processedObject[key] = value;
        }
      }
      // 将 已处理的 旅游配套 资料加入packageObject
      this.packageObject = processedObject;
    },

    /**
     * 更换显示页面:
     * 1. 旅游配套资料/行程
     * 2. 单位价钱
     * 3. 付款教学流程
     * 4. 额外资料(包括/不包括的内容) (非所有配套有该页面)
     * @param pageNumber 当前页数
     */
    switchTab(pageNumber) {
      this.tabPage = pageNumber;
    },

    afterLeave() {
      this.displayTab = this.tabPage;
    },

    /**
     * 关闭预约弹窗
     */
    closeReserveModal() {
      this.showReserveModal = false;
    },

    /**
     * 根据 酒店名称 及 人数 寻找对应的价钱
     * 若 该对应价钱为 空值， 则返还 ‘-’ 符号
     * @param hotel 酒店名称
     * @param peopleCount 人数
     */
    getPriceByPeople(hotel, peopleCount) {
      const rate = hotel.rate.find((rate) => rate.people === peopleCount);
      return rate ? rate.price : "-";
    },

    /**
     * 手机号码联系店家
     * 或
     * 查看文件
     */
    navigateTo(link) {
      window.open(link, "_blank");
    },

    /**
     * 手机界面,
     * 点击后会跳至 Line页面，联系店家
     */
    moveToReserveLink() {
      window.open(`https://line.me/R/ti/p/%40${this.line}`);
    },

    /**
     * 复制框内网址 (旅游配套网址)
     */
    copyToClipboard() {
      this.$refs.inputField.select();
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
    },

    /**
     * 复制框内网址 (旅游配套文件网址)
     */
    copyDocumentToClipboard() {
      this.$refs.documentField.select();
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
    },

    /**
     * 显示文字版的日期
     */
    formatDate(date) {
      return this.moment(date * 1000).format("DD MMM YYYY");
    },

    // 解码文章
    decodeHtml(html) {
      var txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    },
  },
};
</script>
<style scoped>
/* Top Nav */
.top-nav {
  display: block;
  width: 100%;
  height: 56px;
  background: #2d3e52;

  @media (max-width: 991px) {
    display: none;
  }
}

.top-nav .container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 1170px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  color: #5a7ca3;
  font-weight: bold;

  @media (min-width: 991px) and (max-width: 1260px) {
    width: 100%;
  }
}

.top-nav a {
  color: #fdb714;
  font-size: 1em;
  text-decoration: none;
}

router-link {
  text-decoration: none;
}

.top-nav .router-link:hover {
  color: #12a0c3;
}

/* Body */
.main-content {
  display: block;
  width: 100%;
  background: #f5f5f5;
}

.main-content .container {
  width: 1170px;
  padding: 16px;
  margin-left: auto;
  margin-right: auto;
  background: white;
  border-radius: 4px;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.15);

  @media (min-width: 991px) and (max-width: 1260px) {
    width: 95%;
  }

  @media (max-width: 991px) {
    width: auto;
  }
}

.title {
  border-bottom: solid 1px #ddd;
  padding-bottom: 20px;
  margin-bottom: 25px;

  @media (max-width: 991px) {
    width: auto;
  }
}

.title h1 {
  color: #000166;
  font-weight: bold;
  text-align: center;
}

.title img {
  display: none;

  @media (max-width: 991px) {
    display: block;
    width: 100%;
  }
}

h4 {
  margin-top: 0;
  color: #000166;
  font-weight: bold;
  font-size: 1.1em;
}
/* Tab List */
.tab-list {
  width: 100%;
  padding-bottom: 20px;
  border-bottom: solid 1px #ddd;
}

.tab-options {
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 991px) {
    flex-direction: column;
  }
}

.tab-options span {
  margin: 0 5px 0 5px;
  padding: 12px 15px;
  color: #fff;
  background: #d9d9d9;
  font-size: 0.95em;
  font-weight: bold;
  text-align: center;
  border: none;
  border-radius: 9px;
  cursor: context-menu;

  @media (max-width: 991px) {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
  }
}

.tab-options .disabled,
.tab-options span:hover {
  background: #000166;
}

/* Tour Program */
.package-program {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.program-info {
  display: flex;
  flex-direction: row;
}

.package-image {
  width: 40%;
  margin: 0 10px;
  display: flex;
  align-items: center;

  @media (max-width: 991px) {
    display: none;
  }
}

.package-image img {
  width: 100%;
}

.package-info {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: 0 10px;
  padding-top: 5px;
  padding-left: 30px;

  @media (max-width: 991px) {
    width: 100%;
    padding-left: 0;
    justify-content: center;
  }
}

.info {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: solid 1px #ddd;
}

.info a {
  line-height: 35px;
  font-size: 0.9em;
}

.info i {
  padding-right: 5px;
}

.info img {
  padding-left: 15px;
  width: 15%;

  @media (max-width: 991px) {
    width: 30%;
  }
}
.info .info-detail {
  color: #949494;
  padding-left: 15px;
}

.info .price {
  display: flex;
  flex-direction: row;
  width: fit-content;
  align-items: flex-end;
  text-align: right;
  color: #6b6b6b;
}

.info .price h1 {
  font-weight: bold;
  color: #ec1d24;
  margin: 0;
  padding: 0 15px;
  line-height: 30px;
}

.info .price a {
  color: #949494;
  padding-bottom: 3px;
  line-height: 20px;
}

/* Highlight */
.highlight {
  padding: 20px;
}

.highlight-box {
  padding: 30px 0;
  padding-left: 3%;
  margin: 30px 0;
  border-top: solid 1px #ddd;
  border-bottom: solid 1px #ddd;
}

.highlight-box h3 {
  color: #000166;
}

.highlight-box i {
  padding-right: 10px;
}

.highlight-box ul {
  list-style-type: square;
  padding-left: 5%;
}

.highlight-box li {
  margin-left: 20px;
  line-height: 30px;
}

/* Program Detail */
.program-detail {
  padding: 20px;
  margin-bottom: 10px;

  @media (max-width: 991px) {
    padding: 5px;
  }
}

.program-detail h1 {
  color: #000166;
}

.detail-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 4%;
  margin-bottom: 15px;

  @media (max-width: 991px) {
    flex-direction: column;
    margin-left: 0;
  }
}

.detail-item i {
  font-size: 2em;
  margin-left: -1px;
  color: #e6e6ff;

  @media (max-width: 991px) {
    display: none;
  }
}

.detail-index {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  font-size: 0.9em;
  color: white;
  background: #000166;
  border: 7px solid #e6e6ff;
  border-radius: 50px;

  @media (max-width: 991px) {
    margin-bottom: 5px;
  }
}

.detail-info {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  background: #f1f1f1;
  border: none;
  border-radius: 10px;
}

/* Contact Panel */
.button-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 15px;

  @media (max-width: 991px) {
    flex-direction: column;
  }
}

.button-list button {
  width: 300px;
  padding: 5px 0 5px;
  margin: 5px;
  background: #fff;
  font-size: 20px;
  border-radius: 8px;
  cursor: pointer;
  @media (max-width: 991px) {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

.call-button i,
.line-button i,
.view-button i {
  padding-right: 20px;
}

.call-button {
  color: #2ccbc1;
  border: 2px #2ccbc1 solid;
}

.call-button:hover {
  background: #2ccbc1;
  color: #fff;
}

.line-button {
  color: #00b900;
  border: 2px #00b900 solid;
}

.line-button:hover {
  background: #00b900;
  color: #fff;
}

.web-line {
  display: block;

  @media (max-width: 991px) {
    display: none;
  }
}

.mobile-line {
  display: none;

  @media (max-width: 991px) {
    display: block;
  }
}

.view-button {
  color: #fa7624;
  border: 2px #fa7624 solid;
}

.view-button:hover {
  background: #fa7624;
  color: #fff;
}

/* Social Media */
.social-media {
  display: flex;
  flex-direction: column;
  text-align: right;
  padding-right: 4%;
  padding-bottom: 3%;
  margin-top: 20px;
  @media (max-width: 991px) {
    padding: 0;
  }
}

.social-media .social-list {
  @media (max-width: 991px) {
    display: flex;
    justify-content: center;
  }
}

.social-media a {
  margin-top: 15px;
  margin-bottom: 15px;
}

.social-img {
  padding: 8px;
  margin: 0 3px;
  color: white;
  font-size: 30px;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
}

.fa-facebook {
  background: #4267b2;
}

.fa-facebook:hover {
  background: #3a5a9b;
}

.fa-facebook-messenger {
  background: #168aff;
}

.fa-facebook-messenger:hover {
  background: #1582ef;
}

.social-list .fa-line {
  background: #06c755;
}

.social-list .fa-line:hover {
  background: #06b54f;
}

.fa-envelope {
  background: rgb(199, 41, 41);
}

.fa-envelope:hover {
  background: rgb(187, 39, 39);
}

.fa-x-twitter {
  background: #000000;
}

.fa-x-twitter:hover {
  background: #000000b7;
}

.link {
  @media (max-width: 991px) {
    display: flex;
    margin-top: 20px;
  }
}

.link .input-field {
  width: 300px;
  line-height: 35px;
  padding-left: 20px;
  margin-top: 20px;
  border: none;
  border-radius: 0;
  font-size: 0.9em;
  background: #fbfcec;
  cursor: auto;

  @media (max-width: 991px) {
    width: 70%;
    margin: 0;
  }
}

.document-link {
  margin-bottom: 50px !important;
}

.copy-button {
  padding: 0 10px 0 10px;
  line-height: 35px;
  font-size: 0.9em;
  border: none;
  border-radius: 0;
  background: #000166;
  color: white;
  cursor: pointer;

  @media (max-width: 991px) {
    width: 30%;
  }
}

.document-button {
  background: #a82424 !important;
}

/* Service Rate */
.service-rate {
  padding: 20px;
}

.service-rate table {
  width: 100%;
  border: 1px solid #ddd;
  border-collapse: collapse;

  @media (max-width: 991px) {
    display: none;
  }
}

.service-rate thead {
  background-color: #000166;
  color: white;
  height: 30px;
}

.service-rate tr {
  height: 40px;
}

.service-rate th {
  border: 1px solid #ddd;
}

.service-rate td {
  padding: 15px 5px 5px 10px;
  border: 1px solid #ddd;
}

.service-rate .name {
  width: 50%;
  text-align: left;
}

.service-rate .price {
  text-align: center;
  color: #7d1b7e;
}

.reserve {
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 991px) {
    justify-content: space-between;
    margin-top: 10px;
  }
}

.reserve button {
  padding: 10px 15px;
  color: white;
  border: none;
  border-radius: 3px;
}

.reserve .collapse-call {
  background: #100c8a;
}

.reserve .collapse-call:hover {
  background: #0b0b5b;
}

.reserve .collapse-reserve {
  background: #98ce44;
}

.reserve .collapse-reserve:hover {
  background: #7fb231;
}

/* Date And Price ( Mobile ) */
.mobile-collapse {
  display: none;

  @media (max-width: 991px) {
    display: block;
  }
}

.mobile-collapse .collapse-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mobile-collapse::v-deep .el-collapse-item__header {
  height: 80px;
  line-height: 25px;
}

.collapse-item {
  font-weight: bold;
  color: #000166;
}

.collapse-value a,
.group {
  font-weight: bold;
  color: #27ae60;
}

/* Condition */
.condition {
  padding: 20px;
}

.include {
  padding-bottom: 30px;
  margin-bottom: 40px;
  border-bottom: solid 1px #ddd;
}

.notice-box h3 {
  padding-left: 3%;
}

.notice-box i {
  padding-right: 10px;
}

.notice-box ul {
  list-style-type: square;
  padding-left: 5%;
}

.notice-box li {
  margin-left: 20px;
  line-height: 30px;
}

/* Similar Program */
.similar-program {
  width: 100%;
  padding: 30px 0;
  background: #f5f5f5;
}

.similar-program .container {
  width: 1170px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 991px) and (max-width: 1260px) {
    width: 95%;
  }

  @media (max-width: 991px) {
    width: 80%;
  }
}

/* Reserve Modal */
.modal {
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  left: 0;
  top: 0;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);

  @media (max-width: 991px) {
    display: none;
  }
}

.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.2s;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}

/* Fade Transition for Tab Content */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* Loading */
.loading-screen {
  width: 100%;
  height: 300px;
  text-align: center;
  padding-top: 50px;
}

.loading-screen i {
  color: #828282;
  font-size: 3em;

  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

<template>
  <div class="container">
    <h1>{{ $t("orderList") }}</h1>
    <!-- Filter -->
    <div class="list-filter">
      <!-- Select Status -->
      <ul>
        <li
          :class="statusValue === 1 ? 'selected' : ''"
          @click="changeStatus(1)"
        >
          {{ $t("paymentPending") }}
        </li>
        <li
          :class="statusValue === 2 ? 'selected' : ''"
          @click="changeStatus(2)"
        >
          {{ $t("paymentSettled") }}
        </li>
        <li
          :class="statusValue === 3 ? 'selected' : ''"
          @click="changeStatus(3)"
        >
          {{ $t("ticketIssued") }}
        </li>
      </ul>
    </div>
    <!-- Order List -->
    <div class="order-list">
      <div
        class="order-box"
        v-for="order in paginatedItems"
        :key="order.order_id"
      >
        <OrderItem
          :order_id="order.order_id"
          :ticket_type="order.ticket_type"
          :travel_plan="order.travel_plan"
          :order_date="order.order_date"
          :start_date="order.start_date"
          :end_date="order.end_date"
          :number_of_adults="order.number_of_adults"
          :number_of_children="order.number_of_children"
          :order_status="order.order_status"
          :total_price="order.total_price"
        ></OrderItem>
      </div>
    </div>
    <!-- Pagination -->
    <div class="pagination">
      <button
        class="previous-button"
        @click="previousPage"
        :disabled="currentPage === 1"
      >
        {{ $t("previousPage") }}
      </button>
      <div v-for="(pageNumber, index) in visiblePages" :key="pageNumber">
        <a
          style="font-weight: bold"
          v-if="index !== 0 && visiblePages[index - 1] !== pageNumber - 1"
          >...</a
        >
        <span
          :class="
            'page-button' + [currentPage == pageNumber ? ' disabled' : '']
          "
          v-if="totalPages > 1"
          :key="pageNumber"
          @click="gotoPage(pageNumber)"
          :disabled="currentPage === pageNumber"
        >
          {{ pageNumber }}</span
        >
      </div>
      <span class="page-button disabled" v-if="totalPages < 2">1</span>
      <button
        class="next-button"
        @click="nextPage"
        :disabled="currentPage === totalPages || totalPages === 0"
      >
        {{ $t("nextPage") }}
      </button>
    </div>
  </div>
</template>
<script setup>
import orderData from "../../json/order.json";
import OrderItem from "../../components/item/orderItem.vue";
import { ref, computed } from "vue";

const statusValue = ref(1);
const itemsPerPage = ref(4);
const currentPage = ref(1);
// const totalCount = ref(0);

const orderArray = ref(orderData);

const filteredOrder = computed(() => {
  if (statusValue.value != 0) {
    return orderArray.value.filter(
      (order) => order.order_status === statusValue.value
    );
  } else {
    return orderArray.value;
  }
});

const changeStatus = (status) => {
  statusValue.value = status;
  currentPage.value = 1;
};

/**
 * 获取该页面可显示的物件数量 (一个页面最多八个物件，以此类推)
 */
const paginatedItems = computed(() => {
  const startIndex = (currentPage.value - 1) * itemsPerPage.value;
  const endIndex = startIndex + itemsPerPage.value;
  return filteredOrder.value.slice(startIndex, endIndex);
});

/**
 * 获取计算后的页面总数
 */
const totalPages = computed(() => {
  return Math.ceil(filteredOrder.value.length / itemsPerPage.value);
});

/**
 * 获取当前显示的页面号码
 */
const visiblePages = computed(() => {
  let visiblePages = [];
  visiblePages.push(1);

  if (!totalPages.value && !currentPage.value)
    for (let i = 1; i <= totalPages.value; i++) {
      if (currentPage.value == i && !visiblePages.includes(i)) {
        visiblePages.push(i);
      } else if (i === totalPages.value && !visiblePages.includes(i)) {
        visiblePages.push(i);
      } else if (i === currentPage.value - 1 && !visiblePages.includes(i)) {
        visiblePages.push(i);
      } else if (i === currentPage.value + 1 && !visiblePages.includes(i)) {
        visiblePages.push(i);
      }
    }

  return visiblePages;
});

/**
 * 显示下个页面的物件
 */
const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
    window.scrollTo(0, 0);
  }
};

/**
 * 显示上个页面的物件
 */
const previousPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
    window.scrollTo(0, 0);
  }
};

/**
 * 移动至选定页面
 */
const gotoPage = (pageNumber) => {
  currentPage.value = pageNumber;
  window.scrollTo(0, 0);
};
</script>
<style scoped>
.container {
  height: 100%;
  padding: 50px;
}

h1 {
  margin: 0 0 30px 0;
}

/* Filter */
.list-filter {
  width: 85%;
  margin: 10px 0;

  ul {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    list-style-type: none;
  }

  li {
    padding: 15px 20px;
    color: rgb(166, 166, 166);
    font-size: 1.1em;
    font-weight: bold;
    text-align: center;
    background-color: rgba(154, 154, 154, 0.142);
    border-radius: 5px;
    transition: color 0.2s ease, background-color 0.2s ease;
    cursor: pointer;

    &:hover {
      color: rgb(105, 165, 255);
    }
  }

  @media (max-width: 760px) {
    width: 100%;
    margin: 0;

    ul {
      padding: 0;
      flex-direction: column;
    }

    li {
      margin: 5px 0;
      font-size: 1.3em;
    }
  }
}

.selected {
  color: rgb(81, 129, 201) !important;
  background-color: rgba(221, 221, 221, 0.266) !important;
}

/* Order List */
.order-list {
  min-height: 660px;
}

/* Pagination */
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0 20px 0;
  font-weight: bold;
}

.pagination button {
  padding: 12px;
  color: #fff;
  font-size: 0.95em;
  font-weight: bold;
  background: #000166;
  border: none;
  cursor: pointer;
}

.pagination button:disabled {
  color: #fff;
  background: #d9d9d9;
  border: none;
  cursor: context-menu;
}

.previous-button {
  margin-right: 5px;
}

.next-button {
  margin-left: 5px;
}

.pagination .page-button {
  margin: 0 5px 0 5px;
  padding: 12px;
  color: #fff;
  background: #d9d9d9;
  font-size: 0.95em;
  text-align: center;
  border: none;
  cursor: context-menu;
}

.pagination .disabled,
.pagination .page-button:hover {
  background: #000166;
}
</style>

<template>
  <div class="tour-item" @click="navigateToSearch()">
    <div class="container">
      <!-- Image -->
      <div class="tour-image">
        <img
          :src="image || require('../../assets/no-image.svg')"
          :alt="title"
          @error="$event.target.src = require('../../assets/no-image.svg')"
        />
      </div>
      <!-- Info -->
      <div class="tour-info">
        <h3>{{ title }} {{ $t("tour") }}</h3>
        <div class="quantity">
          <a>{{ quantity }}</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import { defineProps } from "vue";

const router = useRouter();

const props = defineProps({
  title: String, // 国内地点 名称
  category: Number, // 国内地点 id
  image: String, // 国内地点 预览图
  quantity: Number, // 国内地点 旅游方案数量
});

/**
 * 进入 搜索旅游资讯 界面
 */
const navigateToSearch = () => {
  router.push({
    path: "/domestic_tour/search",
    query: {
      category: props.category || "",
    },
  });
};
</script>

<style scoped>
/* Body */
.tour-item {
  position: relative;
  width: 93%;
  height: auto;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 2px;
  border: 1px solid #ffffff;
  margin-right: 12px;
  margin-bottom: 12px;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.15);
  cursor: pointer;

  &:hover {
    .container {
      border-color: #12a0c3;
      transition: border-color 0.5s;
    }
    h3 {
      color: #12a0c3;
      transition: color 0.5s;
    }
    img {
      opacity: 0.7;
      transform: scale(1.6);
      transition: opacity 0.5s, transform 0.5s;
    }
  }

  @media (max-width: 991px) {
    width: 97%;
    margin-right: 0;
  }
}

.container {
  border: 2px solid #fff;
  border-radius: 6px;
  transition: border-color 0.5s;
}

/* Tour Image */
.tour-image {
  background: #12a0c3;
  overflow: hidden;
}

.tour-image img {
  width: 250px;
  height: 250px;
  position: relative;
  border-radius: 4px;
  object-fit: cover;
  transform: scale(1.4);
  transition: opacity 0.5s, transform 0.5s;

  @media (max-width: 991px) {
    width: 100%;
    height: 100%;
  }
}

/* Tour Info */
.tour-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

/* Tour Name */
h3 {
  margin: 0;
  padding: 15px 0 15px 0;
  font-size: 0.9em;
  color: #4d4c4c;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  transition: color 0.5s;
}

/* Tour Quantity */
.quantity {
  width: 23px;
  height: 23px;
  position: absolute;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px black solid;
  border-radius: 20px;
}
</style>

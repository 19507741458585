<template>
  <div>
    <el-container>
      <!-- Header -->
      <el-header class="top-nav">
        <div class="container">
          <h3>{{ $t("contactUs") }}</h3>
          <div class="link">
            <a class="router-link" style="margin-right: 5px"
              ><router-link class="nav-route" to="/">{{
                $t("homePage")
              }}</router-link></a
            >
            /
            <a style="margin-left: 5px">{{ $t("contactUs") }}</a>
          </div>
        </div>
      </el-header>

      <!-- Body -->
      <el-main class="main-content">
        <div class="container">
          <div class="contact-us">
            <!-- CONTACT INFO -->
            <div class="contact-panel">
              <h3>{{ $t("contactUs") }}</h3>
              <!-- hotline -->
              <div class="hotline">
                <div class="logo">
                  <i class="fa-solid fa-phone"></i>
                </div>
                <div class="info">
                  <b>{{ $t("contactNumber") }}</b>

                  <a
                    ><a class="office-number" href="tel:06-2158-2884"
                      >06-2158-2884</a
                    >
                    : {{ $t("contactOffice") }}</a
                  >
                  <a
                    ><a class="office-number" href="tel:06-2541-9051"
                      >06-2541-9051</a
                    >
                    : {{ $t("contactOffice") }}</a
                  >
                </div>
              </div>
              <!-- Business Hour -->
              <div class="business-hour">
                <div class="logo">
                  <i class="fa-regular fa-clock"></i>
                </div>
                <div class="info">
                  <b>{{ $t("operateHour") }}</b>
                  <a>{{ $t("mondayToFriday") }}</a>
                  <!-- <a>{{ businessHour }}</a> -->
                </div>
              </div>

              <!-- Address -->
              <div class="address">
                <div class="logo">
                  <i class="fa-solid fa-location-dot"></i>
                </div>
                <div class="info">
                  <b>{{ $t("address") }}</b>
                  <!-- <a>{{ $t("companyAddress") }}</a> -->
                  <a>{{ companyAddress }}</a>
                </div>
              </div>
              <div class="social-media">
                <a :href="`https://www.facebook.com/${facebook}`">
                  <i
                    class="fa-brands fa-facebook social-img"
                    alt="Contact Us on Facebook"
                  ></i>
                </a>
                <a :href="`https://line.me/R/ti/p/%40${line}`">
                  <i
                    class="fa-brands fa-line social-img"
                    alt="Share on Line"
                  ></i>
                </a>
                <!-- Email -->
                <a :href="`mailto:${email}`">
                  <i
                    class="fa-regular fa-envelope social-img"
                    alt="Share on Email"
                  ></i>
                </a>
              </div>
            </div>
            <!-- MAP -->
            <!-- <div class="map-panel">
              <iframe
                width="100%"
                height="600"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d61994.94354546111!2d100.655748!3d13.797915!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29ed09afc669b%3A0x8ad3824647b5d9a4!2z4Lia4Lij4Li04Lip4Lix4LiXIOC4i-C4seC4meC4oeC4ueC4meC4l-C4seC4p-C4o-C5jCDguYHguK3guJnguJTguYwg4LiX4Lij4Liy4LmA4Lin4LilIOC4iOC4s-C4geC4seC4lA!5e0!3m2!1sth!2sth!4v1719902892516!5m2!1sth!2sth"
                ><a href="https://www.gps.ie/"></a
              ></iframe>
            </div> -->
          </div>
          <!-- <div class="contact-quality">
            <div class="content">
              <h3>{{ $t("contactTeam") }}</h3>
              <div class="quality-contact-list">
                <div class="item">
                  <img src="https://www.sunmoon.co.th/images/logo/mylogo.png" />
                  <img
                    :src="
                      companyProfileImage || require('../assets/no-image.svg')
                    "
                    @error="
                      $event.target.src = require('../assets/no-image.svg')
                    "
                  />
                  <a>{{ $t("interestedBooking") }}</a>
                  <a class="contact-number" href="tel:06-2158-2884">
                    <i class="fa-solid fa-phone-volume"></i> 086-360-2262
                  </a>
                </div>
                <div class="item">
                  <img src="https://www.sunmoon.co.th/images/logo/mylogo.png" />
                  <img
                    :src="
                      companyProfileImage || require('../assets/no-image.svg')
                    "
                    @error="
                      $event.target.src = require('../assets/no-image.svg')
                    "
                  />
                  <a>{{ $t("hotline") }}</a>
                  <a class="contact-number" href="tel:06-2158-2884">
                    <i class="fa-solid fa-phone-volume"></i> 06-2158-2884
                  </a>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
export default {
  name: "App",
  computed: {
    // 取得store里面的数据
    companyProfileImage() {
      return this.$store.getters.getCompanyProfileImage;
    },
    contactNumbers() {
      return this.$store.getters.getContactNumbers;
    },
    businessHour() {
      return this.$store.getters.getBusinessHour;
    },
    companyAddress() {
      return this.$store.getters.getCompanyAddress;
    },
    facebook() {
      return this.$store.getters.getCompanyFacebookLink;
      // return "sunmoontourandtravel";
    },
    line() {
      return this.$store.getters.getCompanyLineId;
      // return "gtq9153t";
    },
    email() {
      return this.$store.getters.getCompanyEmail;
      // return "special.sunmoon@gmail.com";
    },
  },
};
</script>
<style scoped>
/* Top Nav */
.top-nav {
  display: block;
  width: 100%;
  height: 56px;
  background: #2d3e52;
}

.top-nav .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1170px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  color: white;

  @media (min-width: 991px) and (max-width: 1260px) {
    width: 100%;
  }

  @media (max-width: 991px) {
    width: 100%;
  }
}

.top-nav .link {
  @media (max-width: 991px) {
    display: none;
  }
}

.top-nav h3 {
  font-weight: 300 !important;
}

.top-nav a {
  color: #fdb714;
  font-size: 1em;
  font-weight: bold;
  text-decoration: none;
}

router-link {
  text-decoration: none;
}

.top-nav .router-link:hover {
  color: #12a0c3;
}

/* Body */
.main-content {
  display: block;
  width: 100%;
  padding: 0px;
  background: #f5f5f5;

  @media (max-width: 991px) {
    width: auto;
  }
}

.main-content .container {
  width: 1170px;
  padding: 16px;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 991px) and (max-width: 1260px) {
    width: 95%;
  }

  @media (max-width: 991px) {
    width: auto;
    padding: 20px 5px;
  }
}

.main-content h3 {
  color: #187cc7;
}

/* Contact Us */
.contact-us,
.contact-quality {
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  border-radius: 2px;
  margin: 20px 0;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.15);

  @media (max-width: 991px) {
    flex-direction: column;
  }
}

.contact-panel {
  width: 35%;
  height: 650px;
  padding: 25px;

  @media (max-width: 991px) {
    width: 85%;
  }
}

.hotline,
.business-hour,
.address {
  width: 100%;
  height: 22%;
  display: flex;
  flex-direction: row;
  margin: 30px 0;
}

.logo {
  width: 20%;
  height: 100%;
  font-size: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #187bc7a6;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  height: 100%;
  padding-left: 20px;
  line-height: 25px;
  border-left: 2px solid #187cc773;
  font-size: 0.9em;
}

a {
  text-decoration: none;
  color: black;
}

.info b {
  color: #187cc7;
}

.info .office-number:hover {
  color: #187bc7a6;
}

.map-panel {
  width: 65%;

  @media (max-width: 991px) {
    width: 100%;
  }
}

.map {
  width: 90%;
  height: 80%;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
}

.social-media {
  width: 100%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.social-img {
  padding: 7px;
  margin: 0 10px;
  color: white;
  font-size: 25px;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
}

.fa-facebook {
  background: #4267b2;
}

.fa-facebook:hover {
  background: #3a5a9b;
}

.fa-line {
  background: #06c755;
}

.fa-line:hover {
  background: #06b54f;
}

.fa-envelope {
  background: rgb(199, 41, 41);
}

.fa-envelope:hover {
  background: rgb(187, 39, 39);
}

/* Contact Quality Team */
.contact-quality .content {
  width: 100%;
  padding: 25px;

  @media (max-width: 991px) {
    width: 80%;
    margin: 5px 0;
  }
}

.quality-contact-list {
  width: 100%;
  display: flex;

  @media (max-width: 991px) {
    flex-direction: column;
  }
}

.quality-contact-list .item {
  width: 20%;
  margin: 0 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #d5d5d5;

  @media (max-width: 991px) {
    width: 95%;
    margin: 5px 0;
  }
}

.quality-contact-list img {
  width: 50%;
  padding: 10px;
}

.quality-contact-list .contact-number {
  margin-top: 10px;
  font-size: 0.9em;
  color: #187cc7;
}
</style>

<template>
  <div v-if="loading" class="loading-screen">
    <i class="fa-solid fa-spinner"></i>
  </div>
  <div v-else>
    <el-container>
      <!-- Header -->
      <el-header class="top-nav">
        <div class="container">
          <a class="router-link" style="margin-right: 5px"
            ><router-link class="nav-route" to="/">{{
              $t("homePage")
            }}</router-link></a
          >
          /
          <a style="margin-left: 5px">{{ $t("travelArticle") }}</a>
        </div>
      </el-header>
      <!-- Body -->
      <el-main class="main-content">
        <div class="container">
          <div class="article-date">
            <h2>{{ articleObject.day }}</h2>
            <a>{{ articleObject.month.toUpperCase() }}</a>
          </div>
          <img
            class="banner"
            :src="articleObject.imageUrl || require('../assets/no-image.svg')"
            @error="$event.target.src = require('../assets/no-image.svg')"
          />
          <div class="share-button" @click="shareToFacebook()">
            <button>
              <i class="fa-brands fa-facebook-f"></i>{{ $t("share") }}
            </button>
          </div>
          <div></div>
          <h1 class="title">{{ articleObject.title }}</h1>
          <p class="description">{{ articleObject.description }}</p>
          <div
            class="article-item"
            v-for="item in articleObject.item"
            :key="item.index"
          >
            <div class="detail-index">
              <a>{{ item.index }}</a>
            </div>
            <h2>{{ item.itemName }}</h2>
            <p>{{ item.info }}</p>
            <div class="knowledge-box" v-if="item.knowledge.length > 0">
              <h3>
                *** <u>{{ $t("knowledge") }}</u> ***
              </h3>
              <div
                class="knowledge-item"
                v-for="knowledge in item.knowledge"
                :key="knowledge.title"
              >
                <h4>{{ knowledge.title }} :</h4>
                <ul v-for="subItem in knowledge.content" :key="subItem">
                  <li>{{ subItem }}</li>
                </ul>
              </div>
            </div>
            <div
              class="img-list"
              v-for="imageLink in item.imageList"
              :key="imageLink"
            >
              <img
                :src="imageLink || require('../assets/no-image.svg')"
                @error="$event.target.src = require('../assets/no-image.svg')"
              />
              <a>- {{ item.location }} -</a>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import articleData from "../json/article.json";
import axios from "axios";
import config from "../api/config.json";

export default {
  name: "App",
  data: function () {
    return {
      articleArray: articleData,

      currentLink: window.location.href,
      loading: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 500);

    // this.fetchArticleData();
  },
  computed: {
    /**
     * 获取指定的物件配套
     */
    articleObject() {
      // 搜索物件(根据其ID)
      const foundItem = this.articleArray.find(
        (article) => article.articleId === Number(this.$route.query.id)
      );

      // 若找到指定物件
      if (foundItem) {
        document.title = foundItem.title;
        return foundItem;
      }
      return {}; // 若找不到指定物件
    },
  },

  methods: {
    /**
     * 获取 旅游文章 数据
     */
    async fetchArticleData() {
      let queryParams = {
        id: this.$route.query.page || "",
      };
      try {
        // 显示加载中
        this.loading = true;

        // 获取旅游文章数据
        const response = await axios.get(
          `${config.apiBaseUrl}/${config.version}/article`,
          {
            params: queryParams,
          }
        );
        console.log("Article", response.data);
      } catch (error) {
        // 发生错误
        console.error("Error fetching articles:", error);
      } finally {
        // 执行就结束，结束加载中
        this.loading = false;
      }
    },

    /**
     * 分享到脸书
     */
    shareToFacebook() {
      window.open(
        `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          this.currentLink
        )}&display=popup&ref=plugin&src=like`,
        "_blank"
      );
    },
  },
};
</script>
<style scoped>
h2 {
  font-weight: 300;
}

/* Top Nav */
.top-nav {
  display: block;
  width: 100%;
  height: 56px;
  background: #2d3e52;

  @media (max-width: 991px) {
    display: none;
  }
}

.top-nav .container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 1170px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  color: #5a7ca3;
  font-weight: bold;

  @media (min-width: 991px) and (max-width: 1260px) {
    width: 100%;
  }
}

.top-nav a {
  color: #fdb714;
  font-size: 1em;
  text-decoration: none;
}

router-link {
  text-decoration: none;
}

.top-nav .router-link:hover {
  color: #12a0c3;
}

/* Body */
.main-content {
  display: block;
  width: 100%;
  padding: 0px;
  background: #f5f5f5;
}

.main-content .container {
  width: 1170px;
  padding: 16px;
  margin-left: auto;
  margin-right: auto;
  background: white;

  @media (min-width: 991px) and (max-width: 1260px) {
    width: 95%;
  }

  @media (max-width: 991px) {
    width: auto;
  }
}

.banner {
  width: 100%;
}

.share-button {
  width: 100%;
  padding: 5px 0;
  display: flex;
  justify-content: flex-end;
}

.share-button i {
  padding-right: 5px;
}

.share-button button {
  padding: 5px 15px;
  color: white;
  background: #4267b2;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Article Head */
.article-date {
  position: absolute;
  width: auto;
  margin: 25px 0 0 -15px;
  padding: 20px;
  color: white;
  font-size: 1em;
  background: #613f49;
  border-radius: 0 10px 10px 0;
  text-align: center;

  h2 {
    margin-top: 0;
    margin-bottom: 5px;
  }

  @media (max-width: 991px) {
    margin-left: -25px;
    padding: 15px;
    font-size: 0.8em;
  }
}

.title {
  width: 80%;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  @media (max-width: 991px) {
    width: 100%;
  }
}

.description {
  width: 97%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 40px;
  text-indent: 20px;
  line-height: 30px;
}

/* Item */
.article-item {
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  border-top: 1px solid #d5d5d5;
}

.detail-index {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  font-size: 1.2em;
  color: white;
  background: #000166;
  border: 7px solid #e6e6ff;
  border-radius: 50px;

  @media (max-width: 991px) {
    margin-bottom: 5px;
  }
}

.knowledge-box {
  width: 95%;
  padding: 10px 20px;
  margin: 5px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  text-align: left;
  border: 1px solid black;

  h3 {
    margin: 10px 0;
  }

  h4 {
    margin: 10px 0;
  }

  li {
    margin-left: -15px;
  }
}

.article-item p {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-indent: 20px;
  line-height: 30px;
}

.img-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
}

.img-list img {
  width: 85%;

  @media (max-width: 991px) {
    width: 100%;
  }
}

.img-list a {
  margin: 10px 0 25px;
}

/* Loading */
.loading-screen {
  width: 100%;
  height: 300px;
  text-align: center;
  padding-top: 50px;
}

.loading-screen i {
  color: #828282;
  font-size: 3em;

  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

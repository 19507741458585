<template>
  <div class="body">
    <!-- Similar Admission Ticket List -->
    <h3>{{ $t("similarTicket") }}</h3>
    <div v-if="state.loading" class="loading-screen">
      <i class="fa-solid fa-spinner"></i>
    </div>
    <div v-else class="ticket-display">
      <div
        class="item"
        v-for="(ticketItem, index) in filteredArray"
        :key="index"
      >
        <TicketItem
          :page="ticketItem.page"
          :id="ticketItem.id"
          :name="ticketItem.name"
          :countryName="ticketItem.countryName"
          :imageUrl="ticketItem.imageUrl"
          :price="ticketItem.price"
        ></TicketItem>
      </div>
    </div>
  </div>
</template>
<script setup>
import TicketItem from "../../components/item/ticketItem.vue";
import ticketData from "../../json/ticket.json";
import axios from "axios";
// import config from "../../api/config.json";
import { onMounted, defineProps, computed, reactive } from "vue";

const props = defineProps({
  tagList: {
    type: Array, // 景点门票标签
    default: () => [],
  },
  id: String, // 景点门票ID
});

const state = reactive({
  ticketArray: ticketData,
  loading: true,
});

onMounted(() => {
  setTimeout(() => {
    state.loading = false;
  }, 1000);

  fetchTicketData();
});

/**
 * 获取 景点门票 数据
 */
const fetchTicketData = async () => {
  let queryParams = {
    keyword: "",
    countryId: "",
  };

  try {
    // 显示加载中
    state.loading = true;

    // 获取景点门票数据
    const response = await axios.get(
      // `${config.apiBaseUrl}/${config.version}/ticket`,
      "https://catfact.ninja/fact",
      {
        params: queryParams,
      }
    );
    console.log("Ticket", response.data);
  } catch (error) {
    // 发生错误
    console.error("Error fetching tickets:", error);
  } finally {
    // 执行就结束，结束加载中
    state.loading = false;
  }
};

/**
 * 根据搜索标签和当前页面的ID，过滤并返回景点门票数组中符合条件的前四个项。
 */
const filteredArray = computed(() => {
  // 若有搜索标签
  if (
    props.tagList &&
    Array.isArray(props.tagList) &&
    props.tagList.length > 0
  ) {
    const searchTags = props.tagList.map((tag) => tag.toLowerCase());

    return state.ticketArray
      .filter((ticketItem) => {
        // 检查该景点门票 和 目前展示的景点门票(就是现在所在的页面) 有至少一个相同的标签‘tag’
        return (
          ticketItem.tagList &&
          ticketItem.tagList.some((itemTag) =>
            searchTags.includes(itemTag.toLowerCase())
          ) &&
          ticketItem.id !== props.id // 检查该景点门票 和 目前展示的景点门票 有不一样的方案ID
        );
      })
      .slice(0, 4); // 只取首四个结果
  }
  return state.ticketArray.slice(0, 4);
});
</script>
<style scoped>
/* Ticket List */
.body h3 {
  width: 96%;
  margin-top: 10px;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 2px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.15);

  @media (max-width: 991px) {
    width: 85%;
  }
}

.ticket-display {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: stretch;
  flex-wrap: wrap;
  flex-direction: row;

  @media (max-width: 991px) {
    flex-direction: column;
  }
}

.ticket-display .item {
  width: calc((100% / 4));

  @media (max-width: 991px) {
    width: 100%;
  }
}

/* Loading */
.loading-screen {
  width: 100%;
  height: 300px;
  text-align: center;
  padding-top: 50px;
}

.loading-screen i {
  color: #828282;
  font-size: 3em;

  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

<template>
  <div>
    <!-- Modal content -->
    <div class="modal-content">
      <!-- Modal header -->
      <div class="modal-header">
        <h2>Order Ticket</h2>
      </div>
      <!-- Modal body -->
      <div class="modal-body">
        <h3>{{ props.title }}</h3>
        <div class="order-panel">
          <!-- Order Ticket -->
          <div class="order-ticket">
            <div class="ticket-selector">
              <a>{{ $t("adult") }}</a>
              <el-input-number
                v-model="adultTicket"
                :min="0"
                :max="10"
                @change="handleChange"
              />
            </div>
            <div class="ticket-selector">
              <a>{{ $t("children") }}</a>
              <el-input-number
                v-model="childrenTicket"
                :min="0"
                :max="10"
                @change="handleChange"
              />
            </div>
          </div>
          <!-- Order Travel Date -->
          <div class="travel-date">
            <a>{{ $t("startDate") }}</a>
            <el-date-picker
              style="width: 200px; margin: 10px 0 15px 10px"
              v-model="travelStartDate"
              type="date"
              :placeholder="$t('pickADay')"
              format="YYYY/MM/DD"
              value-format="x"
              :disabled-date="disabledDate"
              size="default"
            />
          </div>

          <div class="price-display">
            <h3>
              <i class="fa-solid fa-dollar-sign"></i> {{ $t("totalPrice") }}
            </h3>
            <h2>{{ price }}</h2>
          </div>
        </div>
        <!-- Order Button -->
        <button
          class="button submit-order"
          :class="adultTicket === 0 && childrenTicket === 0 ? 'disable' : ''"
          :disabled="adultTicket === 0 && childrenTicket === 0 ? true : false"
          @click="showOrderModal = true"
        >
          <i class="fa-solid fa-ticket-simple"></i>Order Now
        </button>
      </div>
      <!-- Close button -->
      <div class="modal-footer">
        <button @click="closeModal">{{ $t("close") }}</button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, defineProps, getCurrentInstance } from "vue";
// import { useStore } from 'vuex'

const moment = getCurrentInstance().appContext.config.globalProperties.$moment;

const props = defineProps({
  id: String,
  title: String,
  startDate: Number,
  endDate: Number,
});

// 获取当前
const instance = getCurrentInstance();

// 票数
const adultTicket = ref(0);
const childrenTicket = ref(0);

const adultPrice = ref(3000);
const childrenPrice = ref(2000);

const travelStartDate = ref(props.startDate * 1000);
// const travelEndDate = ref(props.endDate);

// 计算总价钱
const price = computed(() => {
  let total = 0;
  return (
    total +
    adultPrice.value * adultTicket.value +
    childrenTicket.value * childrenPrice.value
  );
});

/**
 * 选择开始时间时，
 * 禁用配套开始日期之前和结束之后的时间
 */
const disabledDate = (time) => {
  let startDateMoment = moment(props.startDate * 1000);
  let dayBeforeStartDate = startDateMoment.subtract(1, "days");

  let endDateMoment = moment(props.endDate * 1000);
  let dayAfterEndDate = endDateMoment.subtract(1, "days");

  if (time.getTime() < dayBeforeStartDate) {
    return time.getTime() < dayBeforeStartDate;
  } else if (time.getTime() > dayAfterEndDate) {
    return time.getTime() > dayAfterEndDate;
  } else {
    // 若未选择任何结束时间，则不禁止任何时间
    return null;
  }
};

/**
 * 关闭窗口
 */
const closeModal = () => {
  instance.emit("closeModal");
};
</script>
<style scoped>
.modal-content {
  background-color: #fefefe;
  margin: 3% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 500px;
  min-width: 400px;
  text-align: center;
  border-radius: 10px;
}

.modal-header {
  padding-bottom: 10px;
  text-align: left;
}

.modal-header h2 {
  margin: 0;
}

.modal-body {
  height: auto;
  margin: 10px 0 20px 0;
  padding: 20px 0;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.modal-footer {
  text-align: right;
}

.modal-footer button {
  margin-right: 10px;
  padding: 10px 20px;
  background: rgb(228, 228, 228);
  color: rgb(103, 103, 103);
  font-size: 1.2em;
  border: none;
  border-radius: 10px;
}

.modal-footer button:hover {
  background: rgb(199, 199, 199);
}

/* Order Panel */
.order-panel {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

/* Order Ticket */
.order-ticket {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
}

.ticket-selector {
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0;
}

/* Order Date */
.travel-date {
  display: flex;
  width: 300px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

/* Order Price */
.price-display {
  display: flex;
  width: 300px;
  height: 70px;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgb(239, 239, 239);
  border-radius: 5px;

  h3 {
    display: flex;
    align-items: center;
    color: rgb(229, 196, 10);
    i {
      margin-right: 5px;
    }
  }
}

/* Order Button */
.button-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 15px;

  @media (max-width: 991px) {
    flex-direction: column;
  }
}

.button {
  width: 300px;
  padding: 5px 0 5px;
  margin: 5px;
  background: #fff;
  color: #00b900;
  border: 2px #00b900 solid;
  font-size: 20px;
  border-radius: 8px;
  cursor: pointer;

  i {
    padding-right: 20px;
  }

  &:hover {
    background-color: #00b900;
    color: #fff;
  }
  @media (max-width: 991px) {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

.disable {
  background-color: #c1c1c1;
  color: #008800;
  border: 2px #008800 solid;
  cursor: not-allowed;

  &:hover {
    background-color: #c1c1c1;
    color: #008800;
  }
}
</style>

<template>
  <div class="package-item" @click="navigateToDetails()">
    <div class="container">
      <!-- Image -->
      <div class="package-image">
        <img
          :src="images || require('../../assets/no-image.svg')"
          :alt="title"
          @error="$event.target.src = require('../../assets/no-image.svg')"
        />
      </div>
      <!-- Title -->
      <h3>{{ title }}</h3>
      <!-- Package Info -->
      <div class="package-info">
        <div class="">
          <i class="fa-solid fa-tags"></i><a>{{ code }}</a>
        </div>
        <div>
          <i class="fa-regular fa-clock"></i>
          <a>{{ duration }}</a>
        </div>
        <div>
          <i class="fa-regular fa-calendar-days"></i>
          <a
            >{{ moment(startDate * 1000).format("ll") }} -
            {{ moment(endDate * 1000).format("ll") }}</a
          >
        </div>
      </div>
      <div class="price-section">
        <div class="package-logo">
          <img
            :src="travelBy || require('../../assets/no-image.svg')"
            :alt="code"
            @error="$event.target.src = require('../../assets/no-image.svg')"
          />
        </div>
        <div class="package-price">
          <a>{{ $t("priceStartAt") }}</a>
          <h1>{{ formattedPrice }}</h1>
          <a>{{ $t("bahtPerPerson") }}</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import { defineProps, computed, getCurrentInstance } from "vue";

const moment = getCurrentInstance().appContext.config.globalProperties.$moment;

const router = useRouter();

const props = defineProps({
  code: String, // 旅游配套编号
  id: Number, // 旅游配套ID
  travelBy: String, // 旅游配套图标
  title: String, // 配套名称
  images: String, // 预览图
  duration: String, // 旅游时长
  startDate: Number, // 开放时间
  endDate: Number, // 结束时间
  price: Number, // 价钱
});

/*
 * 将价钱格式化(加逗号)
 */
const formattedPrice = computed(() => {
  return parseFloat(props.price).toLocaleString("en-US");
});

/**
 * 进入 旅游配套资讯 界面
 */
const navigateToDetails = () => {
  router.push({
    path: `/package/${props.id}`,
  });
  window.scrollTo(0, 0);
};
</script>
<style scoped>
/* Body */
.package-item {
  position: relative;
  width: 93%;
  height: auto;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 2px;
  border: 1px solid #ffffff;
  margin-right: 12px;
  margin-bottom: 12px;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.15);
  cursor: pointer;

  &:hover {
    .container {
      border-color: #12a0c3;
      transition: border-color 0.5s;
    }
    h3 {
      color: #12a0c3;
      transition: color 0.5s;
    }
  }

  @media (max-width: 991px) {
    width: 97%;
    margin-right: 0;
  }
}

.container {
  border: 2px solid #fff;
  border-radius: 6px;
  transition: border-color 0.5s;
}

/* Package Image */
.package-image img {
  width: 100%;
  height: 250px;
  position: relative;
  border-radius: 4px;
  object-fit: cover;

  @media (max-width: 991px) {
    height: 100%;
  }
}

/* Package Name */
h3 {
  max-width: 100%;
  height: 2.3rem;
  margin: 15px 5px 15px 5px;
  font-size: 0.9em;
  color: #4d4c4c;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  transition: color 0.5s;
}

/* Package Info */
.package-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 0 5px 0;
  margin: 0 10px 0 10px;
  border-top: solid 1px #ddd;
  border-bottom: solid 1px #ddd;
}

.package-info div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 3px 0 3px 0;
}

.package-info i {
  color: #fdb714;
  padding-right: 5px;
}

.package-info a {
  font-size: 0.8em;
  color: #6b6b6b;
}

/* Price */
.price-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 10px 15px 10px;
}

.package-logo {
  display: flex;
  align-items: center;
  width: 50%;
}

.package-logo img {
  width: 80%;
  height: auto;
  position: relative;
}

.package-price {
  display: flex;
  flex-direction: column;
  width: fit-content;
  text-align: right;
  font-size: 0.8em;
  color: #6b6b6b;
}

.package-price h1 {
  font-weight: bold;
  color: #ec1d24;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}
</style>

<template>
  <div>
    <el-container>
      <!-- Header -->
      <el-header class="top-nav">
        <div class="container">
          <h3>{{ $t("pastWork") }}</h3>
          <div class="link">
            <a class="router-link" style="margin-right: 5px"
              ><router-link class="nav-route" to="/">{{
                $t("homePage")
              }}</router-link></a
            >
            /
            <a style="margin-left: 5px">{{ $t("pastWork") }}</a>
          </div>
        </div>
      </el-header>

      <!-- Body -->
      <el-main class="main-content">
        <div class="container">
          <NoInfo></NoInfo>
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import NoInfo from "../components/noInfo.vue";

export default {
  name: "App",
  components: {
    NoInfo,
  },
};
</script>
<style scoped>
/* Top Nav */
.top-nav {
  display: block;
  width: 100%;
  height: 56px;
  background: #2d3e52;
}

.top-nav .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1170px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  color: white;

  @media (min-width: 991px) and (max-width: 1260px) {
    width: 100%;
  }

  @media (max-width: 991px) {
    width: auto;
  }
}

.top-nav .link {
  @media (max-width: 991px) {
    display: none;
  }
}

.top-nav h3 {
  font-weight: 300 !important;
}

.top-nav a {
  color: #fdb714;
  font-size: 1em;
  font-weight: bold;
  text-decoration: none;
}

router-link {
  text-decoration: none;
}

.top-nav .router-link:hover {
  color: #12a0c3;
}

/* Body */
.main-content {
  display: block;
  width: 100%;
  padding: 0px;
  background: #f5f5f5;
}

.main-content .container {
  width: 1170px;
  padding: 16px;
  margin-left: auto;
  margin-right: auto;
  background: white;

  @media (min-width: 991px) and (max-width: 1260px) {
    width: 95%;
  }

  @media (max-width: 991px) {
    width: auto;
  }
}
</style>

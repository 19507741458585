<template>
  <div class="tour-package">
    <div class="container">
      <h3><i class="fa-solid fa-briefcase"></i>{{ $t("tourPackage") }}</h3>
      <div class="spliter">
        <div class="lines-column"></div>
        <div class="lines-circle"></div>
        <div class="lines-column"></div>
      </div>
      <h5>
        {{ $t("destinationsActivitiesForYou") }}
      </h5>
      <div v-if="loading" class="loading-screen">
        <i class="fa-solid fa-spinner"></i>
      </div>
      <div v-else class="package-list">
        <div
          class="item"
          v-for="(packageItem, index) in packageArray"
          :key="index"
        >
          <HorizontalPackage
            :code="packageItem.code"
            :id="packageItem.id"
            :travelBy="packageItem.travel_by"
            :title="packageItem.title"
            :images="packageItem.images"
            :duration="packageItem.number_of_days"
            :startDate="packageItem.start_date"
            :endDate="packageItem.end_date"
            :price="packageItem.price_per_person"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HorizontalPackage from "../../components/item/horizontalPackage.vue";

import axios from "axios";
import config from "../../api/config.json";

export default {
  name: "App",
  components: {
    HorizontalPackage,
  },
  data() {
    return {
      packageArray: [],

      loading: true,
    };
  },
  mounted() {
    this.fetchPackageData();
  },
  methods: {
    /**
     * 获取 旅游配套 数据
     */
    async fetchPackageData() {
      let queryParams = {
        category: 3,
      };

      // 显示加载中
      this.loading = true;

      // 获取旅游配套数据
      await axios
        .get(`${config.apiBaseUrl}/${config.version}/plan`, {
          params: queryParams,
        })
        .then((response) => {
          this.packageArray = response.data.data.slice(0, 4);
        })
        .catch((error) => {
          // 发生错误
          console.error("Error fetching packages:", error);
        })
        .finally(() => {
          // 执行就结束，结束加载中
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
/* Tour Package List */
.tour-package {
  position: relative;
  width: 100%;
  height: auto;
  padding-top: 20px;
  padding-bottom: 30px;
  background-color: rgba(239, 77, 133, 0.05);

  @media (max-width: 991px) {
    width: 100%;
  }
}

.tour-package .container {
  width: 1170px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 991px) and (max-width: 1260px) {
    width: 97%;
    padding-left: 15px;
  }

  @media (max-width: 991px) {
    width: 95%;
  }
}

.tour-package .container h3 {
  width: 100%;
  text-align: center;
  color: #4d4c4c;
}

.tour-package .container i {
  margin-right: 10px;
}

.tour-package .container h5 {
  width: 100%;
  text-align: center;
  color: #838383;
}

.spliter {
  position: relative;
  width: 100%;
  height: auto;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spliter .lines-circle {
  position: relative;
  height: 12px;
  width: 12px;
  border: 1px solid #ebc131;
  border-radius: 100%;
  margin-left: 8px;
  margin-right: 8px;
}

.spliter .lines-column {
  position: relative;
  width: 100px;
  border-top: 1px solid #d9d9d9;
}

/* Regular Package List */
.package-list {
  position: relative;
  width: 100%;
  height: auto;
  margin-top: 20px;
  display: flex;
  justify-content: stretch;
  flex-wrap: wrap;
  flex-direction: row;

  @media (max-width: 991px) {
    flex-direction: column;
    padding: 5px;
  }
}

.package-list .item {
  width: calc((100% / 2));

  @media (max-width: 991px) {
    width: 100%;
  }
}

/* Loading */
.loading-screen {
  width: 100%;
  height: 100px;
  margin-bottom: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
}

.loading-screen i {
  color: #828282;
  font-size: 3em;

  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

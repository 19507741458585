<template>
  <div class="main" style="background: #f5f5f5">
    <!-- Carousel -->
    <el-carousel class="banner-carousel" indicator-position="outside">
      <el-carousel-item v-for="bannerImg in carouselList" :key="bannerImg">
        <img
          :src="require('../../assets/' + bannerImg)"
          @error="$event.target.src = require('../../assets/no-image.svg')"
        />
      </el-carousel-item>
    </el-carousel>
    <!-- Big Banner -->
    <!-- <img class="banner-big" src="../../assets/main-banner.jpg" /> -->

    <div class="mobile-header-div">
      <div class="blue-banner">
        <div class="search-collapse-open" @click="toggleCollapse()">
          <a><i class="fa-solid fa-location-dot"></i> ไปเที่ยวไหนดี?</a>
          <i class="fa-solid fa-magnifying-glass"></i>
        </div>
        <Transition name="collapse">
          <div class="search-box mobile-view" v-if="this.showMobileCollapse">
            <h3>{{ $t("searchTourPrograms") }}</h3>
            <a>{{ $t("enterKeyword") }}</a>
            <el-input
              v-model="keyword"
              style="width: 100%; height: 32px; margin: 10px 0 15px 0"
              :placeholder="$t('enterKeyword')"
            />
            <a>{{ $t("selectCityCountry") }}</a>
            <el-select
              v-model="countryValue"
              :placeholder="'-- ' + $t('selectCityCountry') + ' --'"
              style="width: 100%; margin: 10px 0 15px 0"
            >
              <el-option
                v-for="country in countryOptions"
                :key="country.value"
                :label="country.label"
                :value="country.value"
              />
            </el-select>
            <!-- Date Picker -->
            <a>{{ $t("startDate") }}</a>
            <el-date-picker
              style="width: 100%; margin: 10px 0 15px 0"
              v-model="startDate"
              type="date"
              :placeholder="$t('pickADay')"
              format="YYYY/MM/DD"
              value-format="x"
              :disabled-date="disabledStartDate"
              size="default"
            />
            <a>{{ $t("endDate") }}</a>
            <el-date-picker
              style="width: 100%; margin: 10px 0 15px 0"
              v-model="endDate"
              type="date"
              :placeholder="$t('pickADay')"
              format="YYYY/MM/DD"
              value-format="x"
              :disabled-date="disabledEndDate"
              size="default"
            />
            <!-- Price Slider -->
            <div class="price-slider-display">
              <h3>{{ priceSliderValue[0] }}</h3>
              <a>{{ $t("between") }}</a>
              <h3>{{ priceSliderValue[1] }}</h3>
            </div>
            <el-slider
              v-model="priceSliderValue"
              style="margin-bottom: 20px"
              range
              :min="0"
              :max="300000"
            />
            <button @click="navigateToSearchTour()">
              <i class="fa-solid fa-magnifying-glass"></i>{{ $t("search") }}
            </button>
          </div>
        </Transition>
      </div>
    </div>
    <div class="blue-vector-img"></div>
    <!-- Content -->
    <div class="content-body">
      <img
        class="banner-small"
        src="../../assets/small-banner.jpg"
        @click="moveToReserveLink()"
      />

      <div class="container">
        <div class="side-bar web-view">
          <!-- Search Box -->
          <div class="search-box">
            <h3>{{ $t("searchTourPrograms") }}</h3>
            <a>{{ $t("enterKeyword") }}</a>
            <el-input
              v-model="keyword"
              style="width: 100%; height: 32px; margin: 10px 0 15px 0"
              :placeholder="$t('enterKeyword')"
            />
            <a>{{ $t("selectCityCountry") }}</a>
            <el-select
              v-model="countryValue"
              :placeholder="'-- ' + $t('selectCityCountry') + ' --'"
              style="width: 100%; margin: 10px 0 15px 0"
            >
              <el-option
                v-for="country in countryOptions"
                :key="country.value"
                :label="country.label"
                :value="country.value"
              />
            </el-select>
            <!-- Date Picker -->
            <a>{{ $t("startDate") }}</a>
            <el-date-picker
              style="width: 100%; margin: 10px 0 15px 0"
              v-model="startDate"
              type="date"
              :placeholder="$t('pickADay')"
              format="YYYY/MM/DD"
              value-format="x"
              :disabled-date="disabledStartDate"
              size="default"
            />
            <a>{{ $t("endDate") }}</a>
            <el-date-picker
              style="width: 100%; margin: 10px 0 15px 0"
              v-model="endDate"
              type="date"
              :placeholder="$t('pickADay')"
              format="YYYY/MM/DD"
              value-format="x"
              :disabled-date="disabledEndDate"
              size="default"
            />
            <!-- Price Slider -->
            <div class="price-slider-display">
              <h3>{{ priceSliderValue[0] }}</h3>
              <a>{{ $t("between") }}</a>
              <h3>{{ priceSliderValue[1] }}</h3>
            </div>
            <el-slider
              v-model="priceSliderValue"
              style="margin-bottom: 20px"
              range
              :min="0"
              :max="300000"
            />
            <button @click="navigateToSearchTour()">
              <i class="fa-solid fa-magnifying-glass"></i>{{ $t("search") }}
            </button>
          </div>
          <!-- Country Menu -->
          <div class="tour-menu">
            <h3>{{ $t("internationalTours") }}</h3>

            <div
              class="tour-card"
              v-for="country in countryArray.slice(0, 20)"
              :key="country.id"
              @click="navigateToInternational('', country.id)"
            >
              <img
                :src="country.image || require('../../assets/no-image.svg')"
                @error="
                  $event.target.src = require('../../assets/no-image.svg')
                "
              />
              <a> {{ country.title }} {{ $t("tour") }}</a>
            </div>
            <h3>{{ $t("domesticTours") }}</h3>
            <div
              class="tour-card"
              v-for="(state, index) in regionArray.slice(0, 10)"
              :key="index"
              @click="navigateToDomestic(state.id)"
            >
              <img
                :src="state.image || require('../../assets/no-image.svg')"
                @error="
                  $event.target.src = require('../../assets/no-image.svg')
                "
              />
              <a> {{ state.title }} {{ $t("tour") }}</a>
            </div>
          </div>
        </div>
        <!-- Body -->
        <div class="body">
          <!-- Popular Tour Program List -->
          <PopularProgram></PopularProgram>
          <!-- Monthly Recommended Tour Program List -->
          <RecommendProgram></RecommendProgram>
        </div>
      </div>
    </div>
    <!-- Regular Package List -->
    <HomePackageList class="tour-package"></HomePackageList>
    <!-- Cruise List -->
    <HomeCruiseList></HomeCruiseList>
  </div>
</template>
<script>
import { getCurrentInstance } from "vue";
import PopularProgram from "./popularProgram.vue";
import RecommendProgram from "./recommendProgram.vue";
import HomePackageList from "./homePackageList.vue";
import HomeCruiseList from "./homeCruiseList.vue";

export default {
  name: "App",
  components: {
    PopularProgram,
    RecommendProgram,
    HomePackageList,
    HomeCruiseList,
  },
  data() {
    return {
      // 搜索栏
      keyword: "",
      countryValue: 1,
      startDate: "",
      endDate: "",
      priceSliderValue: [1000, 150000],

      moment: getCurrentInstance().appContext.config.globalProperties.$moment,

      carouselList: [
        "main-banner-1.jpg",
        "main-banner-2.jpg",
        "main-banner-3.jpg",
      ],

      showMobileCollapse: false,
    };
  },
  mounted() {
    document.title = this.siteName;
    // document.title = this.$t("companyName");
  },
  computed: {
    // 取得store里面的数据
    bigBanner() {
      return this.$store.getters.getBigBanner;
    },
    smallBanner() {
      return this.$store.getters.getSmallBanner;
    },
    siteName() {
      return this.$store.getters.getSiteName;
    },
    line() {
      return this.$store.getters.getCompanyLineId;
      // return "gtq9153t";
    },
    countryArray() {
      return this.$store.getters.getCountryList;
    },

    /**
     * 搜索设置 国家选项
     */
    countryOptions() {
      let optionList = [{ value: 1, label: this.$t("selectCityCountry") }];

      this.countryArray.forEach((country) => {
        optionList.push({
          value: country.id,
          label: country.title,
        });
      });

      return optionList;
    },

    /**
     *  从store获取地区/州属列表
     */
    regionArray() {
      return this.$store.getters.getStateList;
    },
  },
  methods: {
    /*
     * 搜索旅游方案
     */
    navigateToSearchTour() {
      this.$router.push({
        path: "/tour_list/search",
        query: {
          category: this.countryValue || "",
          keyword: this.keyword || "",
          startDate: this.startDate || "",
          endDate: this.endDate || "",
          startPrice: this.priceSliderValue[0],
          endPrice: this.priceSliderValue[1],
        },
      });
      window.scrollTo(0, 0);
    },

    /**
     * 转移至 外国旅游 界面
     * @param tag 外国旅游 标签
     */
    navigateToInternational(tag, countryId) {
      // 设置请求
      const queryParams = {
        category: countryId || 1,
      };

      // 若要根据标签搜索
      if (tag && tag != "") {
        queryParams.tag = tag;
      }

      // 转页
      this.$router.push({
        path: "/tour_list/search",
        query: queryParams,
      });
      window.scrollTo(0, 0);
    },

    /**
     * 转移至 内地旅游 界面
     * @param tag 内地旅游 标签
     */
    navigateToDomestic(category) {
      // 设置请求
      const queryParams = {
        category: category,
      };

      // 转页
      this.$router.push({
        path: "/domestic_tour/search",
        query: queryParams,
      });
      window.scrollTo(0, 0);
    },

    /**
     * 点击后会跳至 Line页面，联系店家
     */
    moveToReserveLink() {
      window.open(`https://line.me/R/ti/p/%40${this.line}`);
    },

    /**
     * 选择开始时间时，
     * 禁用结束日期之后的时间
     */
    disabledStartDate(time) {
      if (this.endDate && typeof this.endDate === "number") {
        let endDateMoment = this.moment(this.endDate);
        let dayBeforeEndDate = endDateMoment.subtract(1, "days");

        return time.getTime() > dayBeforeEndDate;
      } else {
        // 若未选择任何结束时间，则不禁止任何时间
        return null;
      }
    },

    /**
     * 选择结束时间时，
     * 禁用开始日期之前的时间
     */
    disabledEndDate(time) {
      if (this.startDate && typeof this.startDate === "number") {
        let startDateMoment = this.moment(this.startDate);
        let dayBeAfterStartDate = startDateMoment.add(1, "days");

        return time.getTime() < dayBeAfterStartDate;
      } else {
        // 若未选择任何开始时间，则不禁止任何时间
        return null;
      }
    },

    toggleCollapse() {
      this.showMobileCollapse = !this.showMobileCollapse;
    },
  },
};
</script>
<style scoped>
.main {
  overflow-x: hidden;
}

/* Big Banner */
.banner-big {
  width: 99.1vw;
  margin-bottom: 20px;

  @media (max-width: 991px) {
    margin-bottom: 5px;
  }
}

/* Carousel */
.banner-carousel::v-deep .el-carousel__container {
  width: 99.1vw;
  height: 35vw;

  img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 991px) {
    width: 100%;
    height: 30vw;
  }
}

.banner-carousel::v-deep .el-carousel__indicators {
  position: absolute;
  transform: translateX(-60px);
  bottom: -7px;
}

/* Mobile Header Div */
.mobile-header-div {
  display: none;
  padding: 20px 20px 10px 20px;
  background: #010c82;

  @media (min-width: 0px) and (max-width: 991px) {
    display: block;
  }
}

.search-collapse-open {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 15px;
  background: white;
  color: rgb(127, 127, 127);
  border-radius: 5px;
  cursor: pointer;
  i {
    margin-right: 10px;
    color: rgb(86, 86, 86);
  }
}

/* Collapse Animation */
.collapse-enter-active,
.collapse-leave-active {
  animation: collapse reverse 0.3s ease;
}

.collapse-leave-active {
  animation: collapse 0.3s ease;
}

@keyframes collapse {
  from {
    max-height: 500px;
  }
  to {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media (min-width: 0px) and (max-width: 991px) {
  .blue-vector-img {
    position: relative;
    width: 100%;
    height: 2rem;
    display: block;
    margin-bottom: 30px;
  }

  .blue-vector-img::after {
    display: block;
    content: "";
    position: absolute;
    border-left: 100vw solid #010c82;
    border-bottom: 1.8rem solid transparent;
    border-top: 10px solid #010c82;
    top: -5px;
    left: 0;
  }
}

/* Content */
.content-body {
  width: 1170px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  @media (min-width: 991px) and (max-width: 1260px) {
    width: 99%;
    padding-left: 10px;
    padding-right: 10px;
  }

  @media (max-width: 991px) {
    width: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.banner-small {
  width: 100%;
  margin-bottom: 50px;
  cursor: pointer;

  @media (max-width: 991px) {
    display: none;
  }
}

.content-body .container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 991px) {
    width: 100%;
    flex-direction: column;
  }
}

/* Side Bar */
.side-bar {
  position: relative;
  height: auto;
  width: 25%;

  @media (max-width: 991px) {
    display: none;
    width: 100%;
  }
}

.search-box {
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 12px;
  border-radius: 2px;
  margin-bottom: 10px;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.15);

  @media (max-width: 991px) {
    max-height: 500px;
    overflow: hidden;
  }
}

.search-box h3 {
  width: 100%;
  margin: 0.4em 0 0.6em 0;
  text-align: center;
}

.search-box a {
  margin: 0;
  padding: 0;
  font-size: 0.9em;
  padding-left: 2px;
  color: #4d4c4c;
}

.price-slider-display {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.search-box button {
  width: 100%;
  padding: 12px;
  color: #fff;
  font-size: 0.95em;
  font-weight: bold;
  background: #000166;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.search-option button i {
  padding-right: 5px;
}

/* Country Menu */
.tour-menu {
  width: 100%;
  height: auto;
  position: relative;
  margin-bottom: 20px;
  margin-top: 20px;

  @media (max-width: 991px) {
    display: none;
  }
}

.tour-menu h3 {
  width: 100%;
  margin: 1em 0 0.6em 0;
}

.tour-card {
  position: relative;
  width: 100%;
  height: auto;
  border: 1px solid #bfbfbf;
  display: flex;
  justify-content: stretch;
  align-items: center;
  padding: 4px 10px 4px 10px;
  border-radius: 5px;
  margin-bottom: 5px;
  background-color: #ffffff;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.15);
  border: 2px #12a0c300 solid;
  transition: border-color 0.3s;
  cursor: pointer;

  &:hover {
    border-color: #12a0c3;
    transition: border-color 0.3s;

    & a {
      color: #12a0c3;
      transition: color 0.3s;
    }
  }
}

.tour-card a {
  margin: 0;
  padding: 0;
  font-size: 0.9em;
  padding-left: 2px;
  color: #4d4c4c;
  transition: color 0.3s;
}

.tour-card img {
  width: 40px;
  margin-right: 5px;
  border-radius: 20px;
}

/* Body */
.body {
  height: auto;
  width: 70%;

  @media (max-width: 991px) {
    width: 100%;
  }
}

/* Tour Package List */
.tour-package {
  position: relative;
  width: 100%;
  height: auto;
  padding-top: 20px;
  padding-bottom: 30px;
  background-color: rgba(239, 77, 133, 0.05);

  @media (max-width: 991px) {
    width: 100%;
  }
}

.tour-package .container,
.cruise .container {
  width: 1170px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 991px) and (max-width: 1260px) {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 991px) {
  .tour-package .container {
    width: 95%;
  }
  .cruise .container {
    width: 90%;
  }
}

.tour-package .container h3,
.cruise .container h3 {
  width: 100%;
  text-align: center;
  color: #4d4c4c;
}

.tour-package .container i,
.cruise .container i {
  margin-right: 10px;
}

.tour-package .container h5,
.cruise .container h5 {
  width: 100%;
  text-align: center;
  color: #838383;
}

.spliter {
  position: relative;
  width: 100%;
  height: auto;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spliter .lines-circle {
  position: relative;
  height: 12px;
  width: 12px;
  border: 1px solid #ebc131;
  border-radius: 100%;
  margin-left: 8px;
  margin-right: 8px;
}

.spliter .lines-column {
  position: relative;
  width: 100px;
  border-top: 1px solid #d9d9d9;
}

/* Cruise List */
.cruise {
  position: relative;
  width: 100%;
  height: auto;
  padding-top: 20px;
  padding-bottom: 30px;
}

/* Cruise List */
.cruise-list {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: stretch;
  flex-wrap: wrap;
  flex-direction: row;

  @media (max-width: 991px) {
    flex-direction: column;
  }
}

.cruise-list .item {
  width: calc((100% / 3));

  @media (max-width: 991px) {
    width: 100%;
  }
}
</style>

<template>
  <div class="container">
    <h1>{{ $t("profile") }}</h1>
    <div class="user-info">
      <h2>{{ $t("userInfo") }}</h2>
      <div class="list-item">
        <strong>{{ $t("userId") }}</strong
        ><a>{{ userId }}</a>
      </div>
      <div class="list-item">
        <strong>{{ $t("name") }}</strong
        ><a>{{ username }}</a>
      </div>
      <!-- <div class="list-item">
        <strong>{{ $t("passport") }}</strong
        ><a>{{ passport }}</a>
      </div> -->
    </div>
    <div class="user-contact">
      <h2>{{ $t("contactInfo") }}</h2>
      <div class="list-item">
        <strong>{{ $t("phoneNumber") }}</strong
        ><a>{{ phone }}</a>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

const userId = computed(() => {
  return store.getters.getUser;
  // return "customer1";
});

const username = computed(() => {
  return store.getters.getUsername;
  // return "Tester Man";
});

const phone = computed(() => {
  return store.getters.getPhone;
  // return "0123456789";
});
</script>
<style scoped>
.container {
  height: 100%;
  padding: 50px;
  background: white;
}

h1 {
  margin: 0 0 30px 0;
}

h2 {
  padding-left: 10px;
  margin-bottom: 30px;
  font-size: 1.3em;
  color: rgb(76, 76, 169);
  border-left: 3px solid rgba(0, 0, 255, 0.571);
}

/* User Info */
.user-info,
.user-contact {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.list-item {
  display: flex;
  width: 300px;
  margin: 0 0 20px 15px;

  strong {
    width: 130px;
    padding-bottom: 10px;
    color: rgb(125, 125, 187);
  }

  a {
    width: 170px;
    text-align: start;
  }
}
</style>
